<section class="input-container-section mt-30">
  <div class="input-container">
    <input
      type="text"
      class="inputField"
      (keyup)="assignValues($event, 'flat')"
      (keyup)="emmitData()"
      placeholder="House/ flat number/ Floor No"
    />
  </div>
</section>
<section class="input-container-section mt-30">
  <div class="input-container">
    <input
      type="text"
      class="inputField"
      (keyup)="assignValues($event, 'apart')"
      (keyup)="emmitData()"
      placeholder="Apartment/ Road / Area"
    />
  </div>
</section>
<!-- <section class="input-container-section mt-30">
  <div class="input-container">
    <input
      type="text"
      (keyup)="assignValues($event, 'pocket')"
      (keyup)="emmitData()"
      placeholder="Pocket info"
    />
  </div>
</section> -->
<section class="input-container-section mt-30">
  <div class="input-container">
    <input
      type="text"
      class="inputField"
      (keyup)="assignValues($event, 'pin')"
      (keyup)="emmitData()"
      placeholder="Area pincode *"
    />
  </div>
</section>
<section class="input-container-section mt-30">
  <div class="input-container">
    <input
      type="text"
      class="inputField"
      (keyup)="assignValues($event, 'land')"
      (keyup)="emmitData()"
      placeholder="Landmark"
    />
  </div>
</section>
<section class="input-container-section mt-30">
  <div class="input-container">
    <input
      class="inputField"
      type="text"
      class="inputField"
      (keyup)="assignValues($event, 'cName')"
      (keyup)="emmitData()"
      placeholder="Contact Person"
    />
  </div>
</section>
<section class="input-container-section mt-30">
  <div class="input-container">
    <input
      class="no-spinner"
      type="number"
      class="inputField no-spin"
      (keyup)="assignValues($event, 'cNum')"
      (keyup)="emmitData()"
      placeholder="Contact person number"
    />
  </div>
</section>
<section class="save-as-section">
  <p class="save-as-header">Save As</p>
  <ul class="address-list-container">
    <li
      class="address"
      [ngClass]="{ active: i === activeAddressTypeIndex }"
      *ngFor="let addressType of addressTypeList; let i = index"
      (click)="activeAddressTypeIndex = i"
    >
      <img [src]="addressType?.icon" />
      <p class="address-type-name">{{ addressType?.name }}</p>
    </li>
  </ul>
</section>
