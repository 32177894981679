<button
  mat-ripple
  class="add-order-btn"
  *ngIf="!mobileView"
  (click)="gotoUrl('order-flow/step1')"
>
  Add Order <span class="vertical-line"></span>&nbsp;+
</button>
<button
  mat-ripple
  class="mob-add-order-btn"
  *ngIf="mobileView"
  (click)="gotoUrl('order-flow/step1')"
>
  +
</button>
<section class="order-flow-section" *ngIf="!mobileView">
  <div class="fixed-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div *ngIf="!typeOfMessage(message)">
            <app-notifications
              [message]="message"
              [type]="messageType"
            ></app-notifications>
          </div>

          <div class="searching-container">
            <div class="first-half-section">
              <div class="search-bar-section">
                <input
                  class="search-user"
                  (keyup)="handleSearch($event)"
                  placeholder="Search Mobile, Email, Name, Booking Code"
                />
              </div>
              <div class="dashed"></div>
              <button
                *ngIf="type === 'INPRO'"
                class="resetBtn max-width"
                [matMenuTriggerFor]="menu"
              >
                {{ sortQuery === "" ? "Sort by" : sortLabel }} &nbsp;<img
                  src="{{ dropIcon }}"
                  alt="dropdown-icon"
                />
              </button>
              <button
                *ngIf="type === 'NEW'"
                class="resetBtn max-width"
                [matMenuTriggerFor]="newSorting"
              >
                {{ sortQuery === "" ? "Sort by" : sortLabel }} &nbsp;<img
                  src="{{ dropIcon }}"
                  alt="dropdown-icon"
                />
              </button>
              <div class="date-filters" *ngIf="type === 'INPRO'">
                <button
                  class="sort-button max-width"
                  [matMenuTriggerFor]="dateSorting"
                >
                  {{ dateS }}

                  &nbsp;<img src="{{ dropIcon }}" alt="dropdown-icon" />
                </button>
                <mat-menu #dateSorting="matMenu">
                  <button
                    mat-menu-item
                    *ngFor="let i of dateSortingOptions"
                    (click)="sortDate(i)"
                  >
                    {{ i }}
                  </button>
                </mat-menu>
                <input
                  (change)="setDatesAndSort()"
                  type="date"
                  [(ngModel)]="fromDate"
                  class="max-width"
                />
                <input
                  (change)="setDatesAndSort()"
                  type="date"
                  [(ngModel)]="toDate"
                />
              </div>
              <div class="date-filters" *ngIf="type === 'NEW'">
                <button
                  class="sort-button max-width"
                  [matMenuTriggerFor]="dateSorting"
                >
                  {{ dateS }}
                  &nbsp;<img src="{{ dropIcon }}" alt="dropdown-icon" />
                </button>
                <mat-menu #dateSorting="matMenu">
                  <button mat-menu-item (click)="sortDate('Pickup date')">
                    Pickup date
                  </button>
                </mat-menu>
                <input
                  (change)="setDatesAndSort()"
                  type="date"
                  [(ngModel)]="fromDate"
                />
                <input
                  (change)="setDatesAndSort()"
                  type="date"
                  [(ngModel)]="toDate"
                />
              </div>
              <button class="resetBtn" (click)="resetFilters()">
                Reset filters &nbsp;
              </button>
              <button
                mat-ripple
                class="green-btn-filter max-width"
                (click)="gotoUrl('archive')"
              >
                Archive
              </button>
            </div>

            <!-- <div class="date-filters">
              <select>
                <option>Pickup date</option>
                <option>Delivery date</option>
              </select>

            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="searching-filter-section customer-order">
            <div class="left-section">
              <button
                class="action-selected-button"
                [matMenuTriggerFor]="action"
              >
                Action on selected &nbsp;<img
                  src="{{ dropIcon }}"
                  alt="dropdown-icon"
                />
              </button>

              <mat-menu #action="matMenu">
                <button
                  mat-menu-item
                  *ngIf="multipleBookings.length !== 0 && type === 'NEW'"
                  (click)="handleMultipleBookingsAssign()"
                >
                  Assign Pickup
                </button>
                <button
                  mat-menu-item
                  *ngIf="multipleBookings.length === 0"
                  disabled
                >
                  No Bookings selected!
                </button>
                <button
                  *ngIf="multipleBookings.length !== 0 && type === 'INPRO'"
                  mat-menu-item
                  (click)="assignMultipleDrop()"
                >
                  Assign Drop
                </button>
                <button
                  *ngIf="multipleBookings.length !== 0 && type === 'INPRO'"
                  mat-menu-item
                  (click)="assignMultiOrderReady()"
                >
                  Orders Ready
                </button>
              </mat-menu>

              <button
                *ngIf="type === 'INPRO'"
                class="filter-button"
                [matMenuTriggerFor]="filterMenu"
              >
                {{ selectedFilter === "" ? "Filters" : filterLabel }} &nbsp;<img
                  src="{{ dropIcon }}"
                  alt="dropdown-icon"
                />
              </button>
              <mat-menu #filterMenu="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let i of filters"
                  (click)="filter(i)"
                >
                  {{ i.name }}
                </button>
              </mat-menu>
              <mat-menu #newSorting="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let i of sortingNEW"
                  (click)="sort(i)"
                >
                  {{ i.name }}
                </button>
                <!-- <button mat-menu-item>Assign Drop</button> -->
              </mat-menu>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let i of sortingItems"
                  (click)="sort(i)"
                >
                  {{ i.name }}
                </button>
                <!-- <button mat-menu-item>Assign Drop</button> -->
              </mat-menu>
            </div>

            <div class="right-section">
              <div class="group-section">
                <button
                  mat-ripple
                  class="group-item"
                  (click)="isNewOrderListed = true"
                  (click)="type = 'NEW'; resetFilters(); getData()"
                  [ngClass]="{ active: isNewOrderListed }"
                >
                  New Order
                </button>
                <button
                  mat-ripple
                  class="group-item"
                  (click)="isNewOrderListed = false"
                  (click)="type = 'INPRO'; resetFilters(); getData()"
                  [ngClass]="{ active: !isNewOrderListed }"
                >
                  In process
                </button>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="table-section">
          <div
            *ngIf="orders === null"
            class="loader-container"
            style="display: flex; justify-content: center"
          >
            <app-loader></app-loader>
          </div>
          <table *ngIf="orders !== null" class="{{ type }}">
            <tr class="header-tr">
              <td class="header code" [ngClass]="{ order: type === 'INPRO' }">
                <input type="checkbox" (change)="selectAll()" />
                <b style="margin-left: 10px; margin-top: 3px">Order</b>
              </td>
              <td class="header status" *ngIf="type === 'NEW'">
                <b> Order Date </b>
              </td>
              <td class="header status" *ngIf="type === 'NEW'">
                <b> Pickup </b>
              </td>
              <td class="header status" *ngIf="type === 'INPRO'">
                <b> Pickup Date </b>
              </td>
              <td class="header status" *ngIf="type === 'INPRO'">
                <b> Delivery date </b>
              </td>
              <td class="header customer" *ngIf="type === 'NEW'">
                <b>Customer</b>
              </td>
              <td
                class="header"
                *ngIf="type === 'NEW'"
                [class.address]="type === 'NEW'"
              >
                <b>Address</b>
              </td>
              <td class="header customer" *ngIf="type === 'NEW'">
                <b>Instructions</b>
              </td>
              <td *ngIf="type === 'INPRO'" class="statusInPro">
                <b>Invoice</b>
              </td>
              <td *ngIf="type === 'INPRO'" class="statusInPro">
                <b>Order ready</b>
              </td>
              <td *ngIf="type === 'INPRO'" class="statusInPro">
                <b>Assign delivery</b>
              </td>
              <td *ngIf="type === 'INPRO'" class="statusInPro">
                <b>Delivery done</b>
              </td>
              <!-- <td *ngIf="type === 'INPRO'" class="statusInPro">
                <b>Delivery</b>
              </td> -->

              <td
                align="center"
                *ngIf="type === 'NEW'"
                class="header action align-items-center"
              >
                <b>Actions</b>
              </td>
            </tr>
            <tr
              align="left"
              class="row-height"
              [ngClass]="{
                active: multipleBookings.includes(orderObj),
                'order-date-passed': convertToDate(orderObj?.pickup_date)
              }"
              *ngFor="let orderObj of orders; let i = index"
            >
              <td class="code-row baseline NEW-order" *ngIf="type === 'NEW'">
                <div class="p10">
                  <div class="row d-flex" style="flex-direction: row">
                    <div class="col-md-2">
                      <input
                        type="checkbox"
                        [checked]="isSelectedInMultiple(orderObj.id)"
                        (change)="
                          handleAddOrRemoveFromBookings($event, orderObj)
                        "
                      />
                    </div>
                    <div class="col-md-14">
                      <span class="img-code">
                        <img src="{{ renderIcons(orderObj.orderthrough_id) }}" />
                      </span>
                      <span class="booking-code order-details color-green">{{
                        orderObj?.booking_code
                      }}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td class="code-row order baseline" *ngIf="type === 'INPRO'">
                <div class="row" style="display: flex; flex-direction: row">
                  <div class="col-md-1">
                    <input
                      type="checkbox"
                      [checked]="isSelectedInMultiple(orderObj.id)"
                      (change)="handleAddOrRemoveFromBookings($event, orderObj)"
                    />
                  </div>
                  <div class="col-md-11">

                    <span *ngIf="orderObj?.status >= 3"
                      class="booking-code order-details color-green"
                      (click)="gotoUrl('invoice-flow/' + orderObj.id)">
                  <img src="{{ renderIcons(orderObj.orderthrough_id) }}" />
                  {{ orderObj?.booking_code }}
                </span>
                <span *ngIf="orderObj?.status < 3"
                      class="booking-code order-details color-green">
                  <img src="{{ renderIcons(orderObj.orderthrough_id) }}" />
                  {{ orderObj?.booking_code }}
                </span>
                  
                    <div>
                      <mat-icon class="color-grey">person</mat-icon>
                      {{ orderObj?.name }}
                    </div>
                    <div>
                      <mat-icon class="color-grey">phone</mat-icon>
                      {{ orderObj?.mobile }}
                    </div>
                    <!-- <app-address [address]="formatAddress(orderObj?.address)"></app-address> -->
                    <div class="address-details">
                      <mat-icon class="color-grey">place</mat-icon>
                      <!-- {{ orderObj?.address }} -->
                      <app-address
                        [address]="formatAddress(orderObj?.address)"
                      ></app-address>
                    </div>
                  </div>
                </div>

                <div></div>
              </td>
              <td class="status" *ngIf="type === 'NEW'">
                <span
                  class="normalText text-center"
                  [innerHTML]="displayTime(orderObj?.created_at)"
                >
                </span>
              </td>
              <td align="center" class="status" *ngIf="type === 'NEW'">
                <span class="normalText text-center">
                  {{ orderObj?.pickup_date | date }}
                </span>
                <div>
                  {{ orderObj?.pickup_time ? orderObj?.pickup_time : "" }}
                </div>
              </td>
              <td align="center" class="status" *ngIf="type === 'INPRO'">
                <div class="pickup-option">
                  <span *ngIf="orderObj.pickup_date">{{
                    orderObj?.pickup_date == "0000-00-00"
                      ? "N/A"
                      : "Pickup done"
                  }}</span>
                </div>
                <span
                  *ngIf="orderObj?.pickup_date != '0000-00-00'"
                  class="normalText text-center"
                >
                  {{ formatTime(orderObj?.pickup_date, orderObj?.pickup_time) }}
                </span>
              </td>
              <td align="center" class="status" *ngIf="type === 'INPRO'">
                <div class="pickup-option">
                  <span *ngIf="orderObj.delivery_date">{{
                    orderObj?.delivery_date == "0000-00-00"
                      ? "N/A"
                      : "Delivery Scheduled"
                  }}</span>
                </div>
                <span
                  *ngIf="orderObj?.delivery_date != '0000-00-00'"
                  class="normalText text-center"
                >
                  {{
                    formatTime(orderObj?.delivery_date, orderObj?.delivery_time)
                  }}
                </span>
              </td>

              <td
                align="center"
                class="customer text-center"
                *ngIf="type === 'NEW'"
              >
                <span class="name" style="line-height: 20px"
                  ><b>{{ orderObj?.name }}</b></span
                ><span class="darkGreen">{{ orderObj?.mobile }}</span>
              </td>
              <td *ngIf="type === 'NEW'" [class.address]="type === 'NEW'">
                <!-- <span>{{ orderObj?.address }}</span> -->
                <app-address
                  [address]="formatAddress(orderObj?.address)"
                ></app-address>
              </td>
              <td
                align="center"
                class="customer text-center"
                *ngIf="type === 'NEW'"
              >
                <i>
                  <app-address
                    *ngIf="orderObj?.suggestions?.length !== 0"
                    [address]="orderObj?.suggestions"
                  ></app-address>
                </i>
                <div *ngIf="orderObj?.suggestions?.length === 0">NA</div>
                <!-- <br /><span class="light-text">{{ orderObj?.email }}</span> -->
              </td>
              <!-- <td class="pickup" *ngIf="type === 'NEW'">
                <span class="booking-code" *ngIf="type === 'NEW'">{{
                  orderObj?.pickup_date | date
                  }}</span>
                <br />
                <p style="word-wrap: break-word; line-height: 20px">
                  {{ renderFriendlyTimeSlot(orderObj?.pickup_time) }}
                </p>
              </td> -->
              <td
                class="order-details-new text-center"
                *ngIf="type === 'INPRO'"
                colspan="5"
              >
                <div class="table-data-col">
                  <div class="input-item">
                    <!-- <input class="custom-checkbox" [checked]="orderObj?.status >= 3" type="checkbox" disabled /> -->
                    <div
                      *ngIf="orderObj?.status >= 3"
                      class="invoice-generated"
                    >
                      <!-- Here i add (click)="viewInvoice(orderObj?.id)"
                      style="cursor: pointer" for go to viewInvoice page -->
                      <span
                        (click)="viewInvoice(orderObj?.id)"
                        style="cursor: pointer"
                        >Invoice</span
                      >
                      <img (click)="printBarCode(orderObj.id)" [src]="qrIcon" />
                      <div>
                        {{ displayTime(orderObj?.in_process_at) }}
                      </div>
                    </div>
                    <button
                      *ngIf="orderObj?.status === 2"
                      (click)="gotoUrl('invoice-flow/' + orderObj.id)"
                      class="btn-blue"
                      matRipple
                    >
                      Generate
                    </button>
                  </div>
                </div>
                <div class="table-data-col text-center">
                  <div class="input-item">
                    <div
                      *ngIf="orderObj?.status >= 4"
                      class="invoice-generated"
                    >
                      <img src="{{ tickCircle }}" />
                      <div>
                        {{ displayTime(orderObj?.order_ready_at) }}
                      </div>
                    </div>
                    <button
                      *ngIf="orderObj?.status < 3"
                      class="order-ready-button"
                      matRipple
                    ></button>
                    <button
                      *ngIf="orderObj?.status === 3"
                      class="order-ready-button"
                      matRipple
                      (click)="updateOrder(orderObj.id)"
                    ></button>
                    <!-- <input class="custom-checkbox" [checked]="orderObj?.status >= 4" type="checkbox" disabled /> -->
                  </div>
                </div>
                <div class="table-data-col text-center">
                  <div class="input-item">
                    <!-- <input class="custom-checkbox" type="checkbox" [checked]="orderObj?.status >= 2" disabled /> -->
                    <div *ngIf="orderObj?.status >= 5">
                      <img src="{{ tickCircle }}" />
                      <div>
                        {{ displayTime(orderObj?.drop_rider_assign_at) }}
                      </div>
                    </div>
                    <button
                      *ngIf="orderObj?.status < 4"
                      class="btn-blue inactive"
                      matRipple
                      [title]="'Click here to Assign Delivery'"
                    >
                      Assign Delivery
                    </button>
                    <button
                      *ngIf="orderObj?.status === 4"
                      class="btn-blue"
                      matRipple
                      (click)="assignDrop(orderObj.id)"
                      [title]="'Click here to Assign Delivery'"
                    >
                      Assign Delivery
                    </button>
                  </div>
                </div>
                <div class="table-data-col">
                  <div class="input-item">
                    <ng-container *ngIf="orderObj?.status >= 7; else completeButton">
                      <div class="completed-order">
                        <img [src]="tickCircle" alt="Completed" />
                      </div>
                      <div>
                        Delivered
                      </div>
                    </ng-container>
                    <ng-template #completeButton>
                      <button 
                        class="order-ready-button" 
                        matRipple 
                        (click)="complete(orderObj.id)"
                        [disabled]="orderObj?.status <= 4"
                      ></button>
                    </ng-template>
                  </div>
                </div>
              </td>
              <!-- <td *ngIf="type === 'INPRO'" class="status-table" style="border: transparent">
                <div class="input-main-container">
                  <div>
                    <p>
                      {{
                      validateDate(orderObj?.pickup_date)
                      ? orderObj?.pickup_date
                      : "-"
                      }}
                    </p>
                    <br />
                    <p>{{ renderFriendlyTimeSlot(orderObj?.pickup_time) }}</p>
                    <br />
                    <p>{{ orderObj?.pick_boy_assign_at | date : "hh:mm a" }}</p>
                    <br />
                  </div>
                </div>
              </td> -->
              <!-- <td *ngIf="type === 'INPRO'" class="status-table">
                <div>
                  <p>
                    {{
                    validateDate(orderObj?.delivery_date)
                    ? orderObj?.delivery_date
                    : "-"
                    }}
                  </p>
                  <br />
                  <p>{{ renderFriendlyTimeSlot(orderObj?.delivery_time) }}</p>
                  <br />
                  <br />
                  <div class="input-container">
                    <div class="input-item">
                      <input class="custom-checkbox" [checked]="orderObj?.status >= 5" type="checkbox" disabled />
                      <p>
                        Drop <br />
                        Assigned
                      </p>
                    </div>
                    <div class="input-item">
                      <input type="checkbox" [checked]="orderObj?.status >= 7" disabled />
                      <p>
                        Delivery<br />
                        Done
                      </p>
                    </div>
                  </div>
                </div>
              </td> -->

              <!-- <td *ngIf="type === 'INPRO'" class="action-row">
                <span class="td-btn-wrapper actions-inPro">
                  <div class="actionsBtn">
                    <span *ngIf="orderObj?.status === 3" class="green-btn" matRipple (click)="updateOrder(orderObj.id)"
                      style="background-color: '#f0aa03'">
                      <p>Order ready</p>
                    </span>

                    <button *ngIf="orderObj?.status === 4"
                    class="green-btn" matRipple (click)="assignDrop(orderObj.id)"
                    [title]="'Click here to Assign Drop'">
                    Assign Drop
                    </button>

                    <span *ngIf="orderObj?.status > 4" class="green-btn" matRipple style="background-color: '#65ba0d'"
                      (click)="complete(orderObj.id)">
                      <p>Complete</p>
                    </span>
                    <span *ngIf="orderObj?.status === 2" (click)="gotoUrl('invoice-flow/' + orderObj.id)"
                      class="green-btn" matRipple style="background-color: '#f13f18'">
                      <p>Generate Invoice</p>
                    </span>
                  </div>
                  <br />
                  <div class="actionSecondryBtns">
                    <span *ngIf="orderObj.status >= 3">
                      <button style="
                          min-width: 0px !important;
                          padding: 0px;
                          margin-right: 2px;
                        " mat-button [matMenuTriggerFor]="menu">
                        {{
                        orderObj.payment_status
                        ? renderPayment(orderObj.payment_status)
                        : "Select payment"
                        }}
                      </button>
                      <mat-menu #menu="matMenu">
                        <ng-container *ngFor="let item of paymentOptions">
                          <button (click)="updatePayment(orderObj?.id, item.value)" mat-menu-item [value]="item.value">
                            {{ item.label }}
                          </button>
                        </ng-container>
                      </mat-menu>
                    </span>
                    <span *ngIf="orderObj.status >= 3" (click)="printBarCode(orderObj.id)"><img class="imgIcon"
                        src="{{ assets }}../../../../assets/images/barcode.svg" /></span>
                    <span *ngIf="type === 'INPRO' && orderObj.status < 7" (click)="handleCancel(orderObj.id)"><img
                        class="imgIcon" src="{{ assets }}../../../../assets/images/delete.svg" /></span>
                  </div>
                </span>
              </td> -->

              <td *ngIf="type === 'NEW'" class="action-row-new NEW-action">
                <span class="td-btn-wrapper">
                  <button
                    *ngIf="orderObj?.status === 1"
                    class="green-btn"
                    matRipple
                    (click)="assignPickup(orderObj.id)"
                    [title]="'Click here to Assign Pickup'"
                  >
                    Assign Pickup
                    <img src="{{ pickupIcon }}" />
                  </button>
                  <span class="OR"> OR </span>
                  <button
                    *ngIf="type === 'NEW'"
                    class="white-button"
                    (click)="handleCancel(orderObj.id)"
                  >
                    Cancel Order
                  </button>
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-4 color-grey pt10">
        Showing {{ (currentPage - 1) * 30 }} to
        {{ orders?.length < 30 ? orders?.length : currentPage * 30 }} out of
        {{ totalPages * 30 }} results
      </div>
      <div class="col-md-5 text-center">
        <app-pagination
          (page)="updatePageNumber($event)"
          [totalPages]="totalPages"
        ></app-pagination>
      </div>
    </div>
    <!-- <div style="display: flex; justify-content: flex-start">
      <p>
        Showing {{ (currentPage - 1) * 30 }} to
        {{ orders?.length < 30 ? orders?.length : currentPage * 30 }} out of {{ totalPages * 30 }} results </p>
    </div>
    <br />
    <div class="pagination-container">
      <app-pagination (page)="updatePageNumber($event)" [totalPages]="totalPages"></app-pagination>
    </div> -->
  </div>
</section>

<section class="mobile-view" *ngIf="mobileView">
  <div class="row filters-row">
    <div class="col-sm-8 col-xs-8 search">
      <input
        class="search-user"
        (keyup)="handleSearch($event)"
        placeholder="Search the order Here..."
      />
      <img src="{{ assets }}assets/images/icons/search.svg" />
    </div>
    <div class="col-sm-4 col-xs-4">
      <button
        *ngIf="type === 'INPRO'"
        class="resetBtn"
        [matMenuTriggerFor]="menu"
      >
        {{ sortQuery === "" ? "Sort by" : sortLabel }} &nbsp;<img
          src="{{ dropIcon }}"
          alt="dropdown-icon"
        />
      </button>
      <button
        *ngIf="type === 'NEW'"
        class="resetBtn max-width"
        [matMenuTriggerFor]="newSorting"
      >
        {{ sortQuery === "" ? "Sort by" : sortLabel }} &nbsp;<img
          src="{{ dropIcon }}"
          alt="dropdown-icon"
        />
      </button>
      <mat-menu #newSorting="matMenu">
        <button mat-menu-item *ngFor="let i of sortingNEW" (click)="sort(i)">
          {{ i.name }}
        </button>
        <!-- <button mat-menu-item>Assign Drop</button> -->
      </mat-menu>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let i of sortingItems" (click)="sort(i)">
          {{ i.name }}
        </button>
        <!-- <button mat-menu-item>Assign Drop</button> -->
      </mat-menu>
    </div>
  </div>
  <div class="row filters-row">
    <div class="col-sm-3 col-xs-3" *ngIf="type === 'INPRO'">
      <button
        *ngIf="type === 'INPRO'"
        class="filter-button resetBtn"
        [matMenuTriggerFor]="filterMenu"
      >
        {{ selectedFilter === "" ? "Filters" : filterLabel }} &nbsp;<img
          src="{{ dropIcon }}"
          alt="dropdown-icon"
        />
      </button>
      <mat-menu #filterMenu="matMenu">
        <button mat-menu-item *ngFor="let i of filters" (click)="filter(i)">
          {{ i.name }}
        </button>
      </mat-menu>
    </div>
    <div
      class="col-sm-3"
      [ngClass]="type === 'INPRO' ? 'col-xs-3' : 'col-xs-4'"
    >
      <button class="resetBtn" [matMenuTriggerFor]="dateSorting">
        {{ dateS }}
        &nbsp;<img src="{{ dropIcon }}" alt="dropdown-icon" />
      </button>
      <mat-menu #dateSorting="matMenu">
        <button mat-menu-item (click)="sortDate('Pickup date')">
          Pickup date
        </button>
      </mat-menu>
    </div>
    <div
      class="col-sm-3"
      [ngClass]="type === 'INPRO' ? 'col-xs-3' : 'col-xs-4'"
    >
      <input
        (change)="setDatesAndSort()"
        type="date"
        [(ngModel)]="fromDate"
        class="search-user"
        placeholder="DD/MM/YYYY"
      />
    </div>
    <div
      class="col-sm-3"
      [ngClass]="type === 'INPRO' ? 'col-xs-3' : 'col-xs-4'"
    >
      <input
        (change)="setDatesAndSort()"
        type="date"
        [(ngModel)]="toDate"
        class="search-user"
        placeholder="DD/MM/YYYY"
      />
    </div>
  </div>
  <div class="mt10">
    <div class="row">
      <div
        class="col-sm-6 col-xs-6 order new"
        [ngClass]="isNewOrderListed ? 'active' : 'inactive'"
        (click)="isNewOrderListed = true"
        (click)="type = 'NEW'; resetFilters(); getData()"
      >
        New Order
      </div>
      <div
        class="col-sm-6 col-xs-6 order process"
        [ngClass]="isNewOrderListed ? 'inactive' : 'active'"
        (click)="isNewOrderListed = false"
        (click)="type = 'INPRO'; resetFilters(); getData()"
      >
        In Process
      </div>
    </div>
  </div>
  <div class="p20 mobile-item" *ngFor="let orderObj of orders; let i = index">
    <div class="clearfix">
      <input type="checkbox" class="fl v-sub" />
      <span
        class="booking-code order-details color-green ml10 f16"
        (click)="viewInvoice(orderObj?.id)"
      >
        {{ orderObj?.booking_code }}
      </span>
    </div>
    <div class="mt5 f14 color-black bold pl30">
      {{ orderObj?.name }}
    </div>
    <div class="mt5 f14 color-black bold pl30 color-green underline">
      {{ orderObj?.mobile }}
    </div>
    <div class="mt5 f14 color-black pl30" *ngIf="type === 'NEW'">
      {{ orderObj?.address }}
    </div>
    <div class="mt5 f14 color-black pl30" *ngIf="type === 'INPRO'">
      Pickup Date:
      <mat-icon class="color-green f18 v-text-top" *ngIf="orderObj?.pickup_date"
        >calendar_today</mat-icon
      >{{ orderObj?.pickup_date | date }}
      <mat-icon class="color-green f18 v-text-top" *ngIf="orderObj?.pickup_time"
        >access_time</mat-icon
      >{{ orderObj?.pickup_time }}
    </div>
    <div class="mt5 f14 color-black pl30">
      Order Date:
      <mat-icon class="color-green f18 v-text-top" *ngIf="orderObj?.created_at"
        >calendar_today</mat-icon
      >{{ orderObj?.created_at | date }}
      <mat-icon class="color-green f18 v-text-top" *ngIf="orderObj?.created_at"
        >access_time</mat-icon
      >{{ orderObj?.created_at | date : "shortTime" }}
    </div>
    <div class="mt5 f14 color-dark-blue pl30 fw-600" *ngIf="type === 'NEW'">
      Pickup Date:
      <mat-icon class="color-green f18 v-text-top" *ngIf="orderObj?.pickup_date"
        >calendar_today</mat-icon
      >{{ orderObj?.pickup_date | date }}
      <mat-icon class="color-green f18 v-text-top" *ngIf="orderObj?.pickup_time"
        >access_time</mat-icon
      >{{ orderObj?.pickup_time }}
    </div>
    <div
      class="mt5 f14 color-dark-blue-blue pl30 fw-600"
      *ngIf="type === 'INPRO'"
    >
      Delivery Date:
      <mat-icon
        class="color-green f18 v-text-top"
        *ngIf="orderObj?.delivery_date"
        >calendar_today</mat-icon
      >{{ orderObj?.delivery_date | date }}
      <mat-icon
        class="color-green f18 v-text-top"
        *ngIf="orderObj?.delivery_time"
        >access_time</mat-icon
      >{{ orderObj?.delivery_time }}
    </div>
    <div class="mt5 f14 color-black pl30 color-grey" *ngIf="type === 'NEW'">
      {{ orderObj?.suggestions }}
    </div>
    <div class="row pt20" *ngIf="type === 'NEW'">
      <div class="col-sm-6 col-xs-6">
        <button
          class="btn-mobile assign"
          *ngIf="orderObj?.status === 1"
          (click)="handleMultipleBookingsAssign()"
        >
          Assign Pickup
        </button>
      </div>
      <div class="col-sm-6 col-xs-6">
        <button class="btn-mobile cancel" (click)="handleCancel(orderObj.id)">
          Cancel Order
        </button>
      </div>
    </div>
    <div class="order-details-new text-center" *ngIf="type === 'INPRO'">
      <div class="table-data-col">
        <div class="input-item">
          <!-- <input class="custom-checkbox" [checked]="orderObj?.status >= 3" type="checkbox" disabled /> -->
          <div *ngIf="orderObj?.status >= 3" class="invoice-generated">
            <img src="{{ qrIcon }}" />
            <div>{{ displayTime(orderObj?.in_process_at) }}</div>
          </div>
          <button
            *ngIf="orderObj?.status === 2"
            (click)="gotoUrl('invoice-flow/' + orderObj.id)"
            class="btn-blue btn-margin"
            matRipple
          >
            Generate
          </button>
        </div>
      </div>
      <div class="table-data-col text-center">
        <div class="input-item">
          <div *ngIf="orderObj?.status >= 4" class="invoice-generated">
            <img src="{{ tickCircle }}" />
            <div>{{ displayTime(orderObj?.in_process_at) }}</div>
          </div>
          <button
            *ngIf="orderObj?.status < 3"
            class="order-ready-button"
            matRipple
          ></button>
          <button
            *ngIf="orderObj?.status === 3"
            class="order-ready-button"
            matRipple
            (click)="updateOrder(orderObj.id)"
          ></button>
          <!-- <input class="custom-checkbox" [checked]="orderObj?.status >= 4" type="checkbox" disabled /> -->
        </div>
      </div>
      <div class="table-data-col text-center">
        <div class="input-item">
          <!-- <input class="custom-checkbox" type="checkbox" [checked]="orderObj?.status >= 2" disabled /> -->
          <div *ngIf="orderObj?.status >= 5">
            <span> Delivery Asssigned </span>
          </div>
          <button
            *ngIf="orderObj?.status < 4"
            class="btn-blue inactive"
            matRipple
            [title]="'Click here to Assign Delivery'"
          >
            Assign Delivery
          </button>
          <button
            *ngIf="orderObj?.status === 4"
            class="btn-blue"
            matRipple
            (click)="assignDrop(orderObj.id)"
            [title]="'Click here to Assign Delivery'"
          >
            Assign Delivery
          </button>
        </div>
      </div>
      <div class="table-data-col">
        <div class="input-item">
          <!-- <input class="custom-checkbox" type="checkbox" [checked]="orderObj?.status >= 2" disabled /> -->
          <div *ngIf="orderObj?.status >= 7">
            <img src="{{ tickSquare }}" />
          </div>

          <button
            *ngIf="orderObj?.status <= 4"
            class="order-ready-button"
            matRipple
          ></button>
          <button
            *ngIf="orderObj?.status > 4"
            class="order-ready-button"
            matRipple
            (click)="complete(orderObj.id)"
          ></button>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center pt20">
    <app-pagination
      (page)="updatePageNumber($event)"
      [totalPages]="totalPages"
    ></app-pagination>
  </div>
</section>
