<div>
  <h4 class="heading">Edit booking service</h4>
  <br />
  <div *ngIf="exsitingData.parenttrade_id === 4">
    <div class="wash-and-fold-container">
      <div class="info-box-section">
        <ng-container *ngTemplateOutlet="quantityBox"></ng-container>
        <ng-container *ngTemplateOutlet="weightBox"></ng-container>
      </div>
      <div class="btn-wrapper">
        <button class="btn-primary" (click)="handleSubmit()">Update</button>
      </div>
    </div>
  </div>
  <div
    *ngIf="exsitingData.parenttrade_id === 5"
    class="wash-and-iron-container"
    (change)="handleAddOn($event)"
  >
    <div class="info-box-section">
      <ng-container *ngTemplateOutlet="quantityBox"></ng-container>
      <ng-container *ngTemplateOutlet="addOnsHangerPacking"></ng-container>
    </div>
    <div class="btn-wrapper">
      <button class="btn-primary" (click)="handleSubmit()">
        Update Service
      </button>
    </div>
  </div>
  <div
    *ngIf="exsitingData.parenttrade_id === 9"
    class="wash-and-iron-container"
    (change)="handleAddOn($event)"
  >
    <div class="info-box-section">
      <ng-container *ngTemplateOutlet="quantityBox"></ng-container>
    </div>
    <div class="btn-wrapper">
      <button class="btn-primary" (click)="handleSubmit()">
        Update Service
      </button>
    </div>
  </div>
  <div *ngIf="exsitingData.parenttrade_id === 1" (change)="handleAddOn($event)">
    <div class="dry-cleaning-container">
      <div>
        <section class="search-bar-action-container">
          <!-- <app-search-bar
            (keyup)="fetchCloths($event)"
            [itemList]="drycleaningItemList"
            (itemsSelected)="handleCloths($event)"
          ></app-search-bar> -->
          <!-- <input /> -->
        </section>
        <app-edit-cloth
          (dryCleanItems)="handleReciveData($event, 'dryclean')"
          [drycleaningList]="exsitingData.cloths"
          [bookingId]="bookingId"
        ></app-edit-cloth>
        <div class="btn-wrapper section-container">
          <button class="btn-primary" (click)="handleSubmitCloth()">
            Update Service
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="exsitingData.parenttrade_id === 3" (change)="handleAddOn($event)">
    <div class="dry-cleaning-container">
      <div>
        <section class="search-bar-action-container">
          <!-- <app-search-bar
            (keyup)="fetchCloths($event)"
            [itemList]="drycleaningItemList"
            (itemsSelected)="handleCloths($event)"
          ></app-search-bar> -->
          <!-- <input /> -->
        </section>
        <app-edit-cloth
          (dryCleanItems)="handleReciveData($event, 'premiumPress')"
          [drycleaningList]="exsitingData.cloths"
          [bookingId]="bookingId"
        ></app-edit-cloth>
        <div class="btn-wrapper section-container">
          <button class="btn-primary" (click)="handleSubmitCloth()">
            Update Service
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #weightBox>
  <div (change)="handleAddOn($event)" class="gray-box">
    <p class="box-name">Add - Ons / Kg</p>
    <div class="checkbox-option">
      <label for="antisepticWash"> Antiseptic wash</label>
      <input
        class="checkbox-input"
        type="checkbox"
        id="antisepticWash"
        name="antisepticWash"
        value="2"
        name="2"
      />
    </div>
    <div class="checkbox-option">
      <label for="fabricSoftener"> Fabric softener</label>
      <input
        class="checkbox-input"
        type="checkbox"
        id="fabricSoftener"
        name="fabricSoftener"
        value="1"
        name="1"
      />
    </div>
  </div>
</ng-template>

<ng-template #quantityBox>
  <div class="gray-box">
    <p class="box-name"></p>
    <!-- <app-counter
      (count)="handleChange($event, 'qty')"
      [label]="'Qty'"
    ></app-counter> -->
    <div class="box-item">
      <div>Quantity</div>
      <input class="priceQty" type="number" [(ngModel)]="quantity" />
    </div>
    <div class="box-item">
      <div>Weight</div>
      <input class="priceQty" type="number" [(ngModel)]="weight" />
    </div>
    <div class="mt-10"></div>
  </div>
</ng-template>
<ng-template #addOnsHangerPacking>
  <div class="gray-box">
    <p class="box-name">Add - Ons / Item</p>
    <div class="mt-10">
      <app-counter
        (count)="handleChange($event, 'addon')"
        [label]="'Hanger'"
      ></app-counter>
    </div>
  </div>
</ng-template>
