import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public email = '';
  public password = '';
  public message: any = '';
  public messageType = -1;
  public loginImage = `${environment.assetUrl}assets/images/background/login.jpg`;
  constructor(
    private utils: UtilityService,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit(): void {
    console.log(environment.SERVER_URL);
  }

  handleSubmit = () => {
    this.message = '';
    this.messageType = -1;
    this.http
      .post(`${environment.SERVER_URL}stores/login`, {
        email: this.email,
        password: this.password,
      })
      .subscribe(
        (data: any) => {
          this.utils.setLocalStorage(data.data.token, 'token');
          this.router.navigateByUrl('dashboard');
        },
        (err: HttpErrorResponse) => {
          this.message = err.error.message;
          this.messageType = 1;
        }
      );
  }
  handleChange = (e: any, type: string) => {
    switch (true) {
      case type === 'email':
        this.email = e.target.value;
        break;
      case type === 'password':
        this.password = e.target.value;
    }
  }
  typeOfMessage = (type: string | string[]) => {
    return !!Array.isArray(type);
  }
}
