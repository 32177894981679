import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicServerUrlService {
  private serverUrlSubject = new BehaviorSubject<string>('');

  constructor() { }

  getServerUrl() {
    return this.serverUrlSubject.asObservable();
  }

  setServerUrl(serverUrl: string) {
    this.serverUrlSubject.next(serverUrl);
  }
}
