<div
  class="flash-notification"
  *ngIf="message !== ''"
  [ngClass]="{ success: type === 0, error: type === 1 }"
>
  <div>{{ message }}</div>
  <div (click)="dismissNotification()" class="closeModal">
    <mat-icon>close</mat-icon>
  </div>
</div>
