import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UtilityService } from 'src/app/service/utility.service';

@Component({
  selector: 'app-order-flow-step1',
  templateUrl: './order-flow-step1.component.html',
  styleUrls: ['./order-flow-step1.component.scss'],
})
export class OrderFlowStep1Component implements OnInit, OnDestroy {
  public drycleaningItemList: any[] = [];
  public query: any;
  private searchInput$ = new Subject<string>();
  private destroy$ = new Subject<void>();
  constructor(private router: Router, private http: HttpClient, private utils: UtilityService) {
  }

  ngOnInit(): void {
    document.documentElement.style.setProperty('--search-bar-default-width', '80vw');

    this.searchInput$
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => {
        this.performSearchUser();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  performSearchUser = () => {
    this.http
      .get(`${environment.SERVER_URL}webuser/searchUserMobile?mobile=${this.query}&page=1`,
        {
          headers: {
            Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe(
        (data: any) => {
          this.drycleaningItemList = data.data;
        },
        (error) => {
          console.error('performSearchUser: Error fetching users', error);
        }
      );
  }
  onSearchBarItemClick = (event: any) => {
    this.router.navigateByUrl(`/order-flow/step2?query=${this.query}`);
  }
  searchUser = (event: any) => {
    const { value } = event.target;
    const element: any = document.querySelector('#searchbox');
    switch (true) {
      case value === '':
        this.query = '';
        return;
      case value.length > 10:
        this.query = value.slice(0, 9);
        element.value = this.query;
        return;
    }
    // console.log('searchUser: Input updated', value);
    this.query = value;
    this.searchInput$.next(this.query);
  }
}
