import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CreateBookingService } from 'src/app/service/create-booking.service';
import { UtilityService } from 'src/app/service/utility.service';

@Component({
  selector: 'app-order-flow-step2',
  templateUrl: './order-flow-step2.component.html',
  styleUrls: ['./order-flow-step2.component.scss'],
  providers: [CreateBookingService],
})
export class OrderFlowStep2Component implements OnInit {
  private user: any;
  private address: any;
  private customerObj: any;
  private order: any;
  public formStage = 3;
  public addedAddress: any;
  public contactNumber: any;
  public message: any = '';
  public messageType = -1;
  // assets
  public name = `${environment.assetUrl}`;

  constructor(
    private router: Router,
    private http: HttpClient,
    private utils: UtilityService
  ) {}
  typeOfMessage = (type: string | string[]) => {
    if (Array.isArray(type)) {
      return true;
    } else {
      return false;
    }
  }
  ngOnInit(): void {}
  handleAddUser = () => {
    this.message = '';
    this.messageType = -1;
    this.http
      .post(`${environment.SERVER_URL}stores/createNewUser`, this.customerObj,
        {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
       )
      .subscribe(
        (data: any) => {
          this.user = data.user;
          this.formStage = 2;
          this.message = data.message;
          this.messageType = 0;
          console.log(this.user);
          this.router.navigateByUrl(`dashboard/user/${this.user.id}`);
        },
        (err) => {
          this.message = err.error.message;
          this.messageType = 1;
        }
      );
  }
  reciveDataFromChild = (obj: any, type: string) => {
    switch (true) {
      case type === 'add':
        this.address = obj;
        break;
      case type === 'user':
        this.customerObj = obj;
        break;
      case type === 'order':
        this.order = obj;
    }
  }
}
