<section class="customer-directory-section" style="margin-top: 100px">
  <div class="container" style="margin-left: 30px;">
  <app-gst-date-component (dateRangeSelected)="onDateRangeSelected($event)"></app-gst-date-component>
  </div>
  
  
  <div class="container">
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="table-section mt-20">
          <div *ngIf="message">
            <app-notifications [message]="message" [type]="messageType"></app-notifications>
          </div>
          
          <br />
           <div class="dsr-body">
          <div class="group-section">
            <button
              mat-ripple
              class="group-item"
              (click)="showReport('DSR')"
              [class.active]="selectedReport === 'DSR'"
            >
              DSR REPORT
              <div class="active-indicator"></div>
            </button>
            <button
              mat-ripple
              class="group-item"
              (click)="showReport('Collection')"
              [class.active]="selectedReport === 'Collection'"
            >
              Collection Report
              <div class="active-indicator"></div>
            </button>
            <button
              mat-ripple
              class="group-item"
              (click)="showReport('Quantity')"
              [class.active]="selectedReport === 'Quantity'"
            >
              Quantity Report
              <div class="active-indicator"></div>
            </button>
          </div>
          
          <div *ngIf="selectedReport === 'DSR' && data">
            <h2>DSR REPORT</h2>
            <div class="table-section">
              <table>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Pickup Date</th>
                    <th>D.C. Sale</th>
                    <th>PI/KG Sale</th>
                    <th>Laundry (W & I) Sale</th>
                    <th>S.P. Sale</th>
                    <th>S.I. Sale</th>
                    <th>Fabric Softener</th>
                    <th>Antiseptic Wash</th>
                    <th>Hanger Packing</th>
                    <th>Discount</th>
                    <th>Net Amount</th>
                    <th>GST (18%)</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of data; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.date | date:'dd/MM/yyyy' }}</td>
                    <td>{{ item.dc_sale | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.pi_kg_sale | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.laundry_sale | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.sp_sale | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.si_sale | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.fabric_softener | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.antiseptic_wash | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.hanger_packing | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.discount | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.net_amount | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.gst | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.total | currency:'INR':'symbol':'1.2-2' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div *ngIf="selectedReport === 'Collection' && data">
            <h2>COLLECTION REPORT</h2>
            <div class="table-section">
              <table>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Pickup Date</th>
                    <th>Cash</th>
                    <th>Credit/Debit Card</th>
                    <th>UPI/Wallet</th>
                    <th>Bank Transfer</th>
                    <th>Pending</th>
                    <th>Total Payment Received</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of data; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.date | date:'dd/MM/yyyy' }}</td>
                    <td>{{ item.cash | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.card | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.upi_wallet | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.bank_transfer | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.pending | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ item.total_payment | currency:'INR':'symbol':'1.2-2' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div *ngIf="selectedReport === 'Quantity' && data">
            <h2>QUANTITY REPORT</h2>
            <div class="table-section">
              <table>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Pickup Date</th>
                    <th>D.C. Quantity</th>
                    <th>PI/Kg Quantity Pcs.</th>
                    <th>PI/Kg Quantity KG</th>
                    <th>Laundry (W & I) Quantity Pcs.</th>
                    <th>Laundry (W & I) Quantity KG</th>
                    <th>S.P. Qnanity</th>
                    <th>S.I. Quantity</th>
                    <th>Fabric Softener</th>
                    <th>Antiseptic Wash</th>
                    <th>Hanger Packaging</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of data; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.date | date:'dd/MM/yyyy' }}</td>
                    <td>{{ item.dc_quantity }}</td>
                    <td>{{ item.pi_kg_quantity_pcs }}</td>
                    <td>{{ item.pi_kg_quantity_kg }}</td>
                    <td>{{ item.laundry_quantity_pcs }}</td>
                    <td>{{ item.laundry_quantity_kg }}</td>
                    <td>{{ item.sp_quantity }}</td>
                    <td>{{ item.si_quantity }}</td>
                    <td>{{ item.fabric_softener }}</td>
                    <td>{{ item.antiseptic_wash }}</td>
                    <td>{{ item.hanger_packaging }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
         </div>
        </div>
      </div>
    </div>
  </div>
</section>