<div class="section partition">
  <br />
  <!-- <div class="uclean-dflex-spacebetween mt-20"> -->
  <div class="uclean-dflex-spacebetween item uclean-relative w40 mt-10">
    <p class="">Total orders -</p>
    <p class="value">{{ userHistory?.numberOfOrders }}</p>
  </div>
  <div class="uclean-dflex-spacebetween item uclean-relative w50 mt-10">
    <p class="">Total orders Value -</p>
    <p class="value">{{ userHistory?.totalAmountOrderd | number : "1.1-1" }}</p>
  </div>
  <!-- </div> -->
  <div class="uclean-dflex item mt-10">
    <p class="uclean-relative">Last Order -</p>
    <p class="value">{{ userHistory?.latestOrder | date }}</p>
  </div>
</div>
