import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { CreateBookingService } from 'src/app/service/create-booking.service';
import { Observable } from 'rxjs/internal/Observable';
import { UtilityService } from 'src/app/service/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { UpdateUserComponent } from 'src/app/update-user/update-user.component';
import { AddAddressComponent } from 'src/app/add-address/add-address.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-flow-step3',
  templateUrl: './order-flow-step3.component.html',
  styleUrls: ['./order-flow-step3.component.scss'],
  providers: [CreateBookingService],
})
export class OrderFlowStep3Component implements OnInit {
  public currentUser: any;
  public customerAddress: any;
  public customerHistory: any;
  public customerPreviousOrders: Array<any>;
  private id: any;
  public booking$: Observable<any>;
  public order: any = {};
  public userAddress: any;
  public message: any = '';
  public messageType = -1;
  public assets: string = environment.assetUrl;

  constructor(
    private Http: HttpClient,
    private route: ActivatedRoute,
    private createBookingService: CreateBookingService,
    private utils: UtilityService,
    private dialgRef: MatDialog,
    private router: Router
  ) {
    this.route.params.subscribe((e: any) => {
      this.id = e.id;
    });
    this.customerPreviousOrders = [];
    this.booking$ = this.createBookingService.getData();
  }
  openUpdateUser = () => {
    const modal = this.dialgRef.open(UpdateUserComponent, {
      data: {
        userId: this.id,
        user: this.currentUser,
      },
    });
    modal.componentInstance.trigger.subscribe((data) => {
      this.getCustomerDetails();
    });
  }
  openAddressModal = () => {
    const modal = this.dialgRef.open(AddAddressComponent, {
      data: { id: this.id },
    });

    modal.componentInstance.trigger.subscribe((data) => {
      this.getCustomeraddresses();
    });
  }
  typeOfMessage = (type: string | string[]) => {
    if (Array.isArray(type)) {
      return true;
    } else {
      return false;
    }
  }
  getFormattedDate = (date: string) => {
    const newDate = new Date(date).toDateString();
    return newDate;
  }
  ngOnInit(): void {
    document.documentElement.style.setProperty(
      '--search-bar-default-width',
      '80vw'
    );

    this.getCustomerDetails();
    this.getCustomeraddresses();
    this.getCustomerHistory();
    this.getCustomerPreviousOrders();

    this.booking$.subscribe((data) => {});
  }
  resetForm = () => {
    this.order = {};
  }
  handleCreateOrder = () => {
    this.message = '';
    this.messageType = -1;
    if (!this.userAddress) {
      this.message = 'Please provide with the address of the user.';
      this.messageType = 1;
      return;
    }
    this.order.address_id = this.userAddress;
    this.order.webuser_id = this.id;
    // this.order.pickup_id = 0;
    this.order.pickup_instrution = '';
    this.Http.post(`${environment.SERVER_URL}bookings`, this.order, {
      headers: {
        authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
      },
    }).subscribe(
      (data: any) => {
        this.message = data.message;
        this.messageType = 0;
        this.getCustomerHistory();
        this.getCustomerPreviousOrders();
        setTimeout(() => {
          this.router.navigateByUrl('/dashboard');
        }, 2000);
      },
      (err) => {
        this.message = err.error.message;
        this.messageType = 1;
      }
    );
  }

  reciveDataFromChild = (event: any, type: string) => {
    switch (true) {
      case type === 'ADDID':
        this.userAddress = event.address_id;
        break;
      case type === 'ORDER':
        this.order = event;
    }
  }
  getCustomeraddresses = () => {
    this.Http.get(`${environment.SERVER_URL}address/${this.id}`).subscribe(
      (data: any) => {
        this.customerAddress = data.data;
      }
    );
  }

  renderStatus(s: number): string {
    switch (true) {
      case s === 0:
        return 'Cancel';
      case s === 1:
        return 'New order';
      case s === 2:
        return 'Pickup assigned';
      case s === 3:
        return 'In process';
      case s === 4:
        return 'Order ready';
      case s === 5:
        return 'Drop rider assigned';
      case s === 6:
        return 'Out for delivery';
      case s === 7:
        return 'Delivered';
    }
    return '';
  }

  getCustomerHistory = () => {
    this.Http.get(`${environment.SERVER_URL}webuser/getStats/${this.id}`, {
      headers: {
        authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
      },
    }).subscribe((data: any) => {
      this.customerHistory = data.data;
    });
  }
  getCustomerPreviousOrders = () => {
    this.Http.get(`${environment.SERVER_URL}bookings/${this.id}?page=1`, {
      headers: {
        authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
      },
    }).subscribe((data: any) => {
      this.customerPreviousOrders = data.data;
    });
  }
  getCustomerDetails = () => {
    this.Http.get(`${environment.SERVER_URL}webuser/${this.id}`, {
      headers: {
        authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
      },
    }).subscribe(
      (e: any) => {
        this.currentUser = e.data[0];
        console.log(this.currentUser);
      }
    );
  }
}
