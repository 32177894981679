import {Component, OnInit} from '@angular/core';
import {CouponsService} from 'src/app/service/coupons.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-coupon-step4',
  templateUrl: './add-coupon-step4.component.html',
  styleUrls: ['./add-coupon-step4.component.scss']
})
export class AddCouponStep4Component implements OnInit {
  public channelType: number;
  selectedTemplatePath = '';
  step1Data: any;
  step2Data: any;
  step3Data: any;
  selectedServiceStr: string;
  constructor(
    private couponService: CouponsService,
    private http: HttpClient,
    private router: Router,
    private matdialoge: MatDialog,
  ) {
  }

  back = () => {
    this.router.navigateByUrl('add-coupons-step-3');
  }

  goToUrl = (url: string) => {
    this.router.navigateByUrl(url);
  }

  ngOnInit(): void {
    this.couponService.getData(1)?.subscribe((data) => {
      this.step1Data = data;
      this.selectedTemplatePath = this.step1Data.selectedTemplatePath;
    });
    this.couponService.getData(2)?.subscribe((data) => {
      this.step2Data = data;
    });
    this.couponService.getData(3)?.subscribe((data) => {
      this.step3Data = data;
      this.selectedServiceStr = this.getSelectedItemsString();
    });
  }

  getSelectedItemsString(): string {
    const selectedItems = this.step3Data.selectedItems;
    if (this.step3Data.countOfServices === selectedItems.length) {
      return 'all services';
    } else if (selectedItems.length > 2) {
      return `${selectedItems[0]}, ${selectedItems[1]} and others`;
    } else {
      return selectedItems.join(', ');
    }
  }

  handleCreateCoupon = () => {
    const body = {
      image_url: this.step1Data?.selectedTemplateImage,
      title: this.step1Data?.couponTitle,
      description: this.step1Data?.description,
      discount_code: this.step1Data?.discountCode,
      start_date: this.step1Data.start,
      end_date: this.step1Data.end,
      discount_type: parseInt(this.step2Data.couponType),
      discount: this.step2Data.couponType === 1 ? this.step2Data.discount : null,
      flat_rate: this.step2Data.couponType === 2 ? this.step2Data.discount : null,
      minimum_order: this.step2Data.minimumAmount,
      maximum_discount: this.step2Data.couponType === 2 ? this.step2Data.discount : this.step2Data.maximumDiscount,
      order_no: parseInt(this.step2Data.orderType),
      frequency: this.step2Data.orderType === 2 ? this.step2Data.usageLimit : 1,
      selectedParentTrade: this.step3Data.selectedItemIds,
      channel_type: parseInt(this.step1Data.showTemplateSection),
    };
    console.log({body});
    const modal = this.matdialoge.open(ConfirmationModalComponent, {
      data: {
        messageToDisplay: 'Are you sure you want to create this coupon?',
        body,
        postURL: 'coupons',
      },
    });
    modal.componentInstance.trigger.subscribe(() => {
      setTimeout(() => {
        this.couponService.setData(null, 1);
        this.couponService.setData(null, 2);
        this.couponService.setData(null, 3);
        this.goToUrl('coupons-table');
      }, 1000);
    });

  }

}
