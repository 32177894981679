<section class="main-sec">
  <div class="container">
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <div class="row">
          <br/>
          <div class="row-header">
            <div class="col-lg-3">
              <div class="horizontal-line"></div>
              <h6 class="step-1">Step 1</h6>
              <h5 class="Coupon-code-title">Code & Validity</h5>
            </div>
            <div class="col-lg-3">
              <div class="horizontal-line2"></div>
              <h6 class="Step-2">Step 2</h6>
              <h5 class="Service-type-Date">Discount Type</h5>
            </div>
            <div class="col-lg-3">
              <div class="horizontal-line-d"></div>
              <h6 class="Step-3">Step 3</h6>
              <h5 class="Discount-type">Service Type</h5>
            </div>
            <div class="col-lg-3">
              <div class="horizontal-line-d"></div>
              <h6 class="Step-3">Step 4</h6>
              <h5 class="Discount-type">Preview Coupon</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2"></div>
    </div>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8 b-g-white">
        <br>
        <label class="channel-type">Coupon Thumbnail Preview</label>
        <br>
        <br>
        <div class="selected-template-preview" *ngIf="step1Data.selectedTemplateImage">
          <img [src]="step1Data.selectedTemplateImage" alt="Selected Template"/>
          <div class="offer-details">
            <div class="discount">
              <span *ngIf="step2Data.couponType == 1">
                {{ step2Data.discount }}% OFF
              </span>
              <span *ngIf="step2Data.couponType == 2">
                {{ step2Data.maximumDiscount }} OFF
              </span>
            </div>
            <div class="validity">Valid on {{ selectedServiceStr }}</div>
            <div class="code" style="font-weight: bold;">{{ step1Data.discountCode }}</div>
          </div>
        </div>
        <div class="span-btn">
          <div class="btn-wrapper">
            <button class="Backk" (click)="back()">Back</button>
          </div>

          <div class="" style="display: flex; justify-content: center">
            <button class="btn primary next-btn model" (click)="handleCreateCoupon()">Next</button>
          </div>
        </div>
        <br>
        <br>
      </div>

    </div>
  </div>
</section>
