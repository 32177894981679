<div
  class="login-main-container"
  style="background: url({{
    loginImage
  }});  background-repeat: no-repeat ; background-size : cover; height : 100vh ; width : 100%"
>
  <div class="login-form-container">
    <div class="login-heading-container">
      <h3>Feel free to login</h3>
      <hr />
    </div>
    <div *ngIf="!typeOfMessage(message)">
      <app-notifications
        [message]="message"
        [type]="messageType"
      ></app-notifications>
    </div>
    <div *ngIf="typeOfMessage(message)">
      <div *ngFor="let e of message">
        <app-notifications [message]="e" [type]="1"></app-notifications>
      </div>
    </div>
    <form (ngSubmit)="handleSubmit()">
      <div>
        <input
          ng-required="true"
          (keyup)="handleChange($event, 'email')"
          placeholder="Email"
          type="email"
        />
      </div>
      <div>
        <input
          (keyup)="handleChange($event, 'password')"
          type="password"
          placeholder="Password"
          ng-required="true"
        />
      </div>
      <div class="btn-wrapper">
        <button
          type="submit"
          class="btn-primary"
          style="width: 100%; margin-top: 10px; text-align: center"
        >
          Login
        </button>
      </div>
    </form>
  </div>
</div>
