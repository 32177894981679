<div class="partition-2">
  <section class="input-container-section mt-30">
    <div class="input-container">
      <select
        style="
      background: url({{ dropIcon }}) !important;
    background-repeat: no-repeat !important;
    background-position: right 16px !important;
    background-color: #f8f8f8 !important;
    background-size: 45px 10px !important;color : #adadad

    "
        (change)="handleOnChange($event, 'OM')"
        (change)="emitData()"
      >
        <option value="" disabled selected hidden>Order Mode</option>

        <option *ngFor="let o of orderThrough" value="{{ o.id }}">
          {{ o.name }}
        </option>
      </select>
    </div>
  </section>
  <div class="line"></div>
  <section class="input-container-section mt-30">
    <div class="input-container">
      <select
        style="
      background: url({{ dropIcon }}) !important;
    background-repeat: no-repeat !important;
    background-position: right 16px !important;
    background-color: #f8f8f8 !important;
    background-size: 45px 10px !important;color : #adadad

    "
      >
        <option value="" disabled selected hidden>Select Source</option>
        <option value="Pamphlet">Pamphlet</option>
        <option value="SMS">SMS</option>
        <option value="Just dial">Just dial</option>
        <option value="Urban clap">Urban clap</option>
        <option value="Google">Google</option>
        <option value="Flyer">Flyer</option>
        <option value="Signage">Signage</option>
        <option value="Laundertte">Laundertte</option>
        <option value="Kilikly">Kilikly</option>
        <option value="Laundry Village">Laundry Village</option>
        <option value="Friend">Friend</option>
        <option value="Other">Other</option>
      </select>
    </div>
  </section>
</div>
<section class="input-container-section mt-30">
  <div class="input-container">
    <div style="width: 100%">
      <!-- This is dropdown for service selection -->
      <ng-multiselect-dropdown
        style="
          height: 45px;
          width: 100%;
          background: #f8f8f8;
          border-radius: 10px;
          padding-left: 15px;
          border-color: transparent;
          z-index: 1000;
          color: #adadad;
          padding: 0px 50px;
        "
        (onSelect)="onItemSelect($event)"
        [settings]="dropdownSettings"
        [data]="dropdownList"
        (onDeSelect)="onItemDeSelect($event)"
        (onSelectAll)="onSelectAll($event)"
        (onDeSelectAll)="onDeselectAll()"
      >
      </ng-multiselect-dropdown>
    </div>
  </div>
</section>
<div class="partition-2" *ngIf="orderThroughId !== 1">
  <section class="input-container-section mt-30">
    <div class="input-container">
      <select
        style="
      background: url({{ dropIcon }}) !important;
    background-repeat: no-repeat !important;
    background-position: right 16px !important;
    background-color: #f8f8f8 !important;
    background-size: 45px 10px !important;color : #adadad

    "
        (change)="handleOnChange($event, 'PD')"
        (change)="emitData()"
      >
        <option value="" disabled selected>Select Pickup Date</option>

        <option *ngFor="let o of availableDates" value="{{ o }}">
          {{ o }}
        </option>
      </select>
    </div>
  </section>
  <div class="line"></div>
  <section class="input-container-section mt-30">
    <div class="input-container">
      <select
        style="
      background: url({{ dropIcon }}) !important;
    background-repeat: no-repeat !important;
    background-position: right 16px !important;
    background-color: #f8f8f8 !important;
    background-size: 45px 10px !important;color : #adadad

    "
        (change)="handleOnChange($event, 'PT')"
        (change)="emitData()"
      >
        <option disabled selected>Pickup Time</option>
        <option *ngFor="let t of timeslots" value="{{ t }}">
          {{ t }}
        </option>
      </select>
      <!-- <input
        type="text"
        ngModel
        name="pickupTime"
        placeholder="Pickup Time"
        onfocus="(this.type='time')"
        onfocusout="(this.type='text')"
      /><br /> -->
    </div>
  </section>
</div>

<section class="input-container-section mt-30">
  <div class="input-container">
    <textarea
      [(ngModel)]="suggestions"
      (change)="emitData()"
      class="text-box-suggestions"
      placeholder="Instructions"
    ></textarea>
  </div>
</section>
<div class="btn-wrapper">
  <!-- <button class="btn-primary">Place Order</button> -->
</div>
