import { HttpClient } from '@angular/common/http';
import {
  EventEmitter,
  Component,
  OnInit,
  ElementRef,
  Inject,
  Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
// import { EventEmitter } from 'stream';
import { UtilityService } from '../service/utility.service';
// import { EventEmitter } from 'stream';
@Component({
  selector: 'app-store-runner',
  templateUrl: './store-runner.component.html',
  styleUrls: ['./store-runner.component.scss'],
})
export class StoreRunnerComponent implements OnInit {
  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  public mobile: string = '';
  public profile: string = '';
  public password: string = '';
  public functionality: number;

  // Update State
  public name: string;
  public mobileNo: string;
  public status: string;
  private id: number;

  public profileExists: any;

  @Output() trigger = new EventEmitter<any>();

  public message: any = '';
  public messageType: number = -1;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private utils: UtilityService,
    private dialogRef: MatDialogRef<StoreRunnerComponent>
  ) {
    this.functionality = data.type;
    this.name = data.profile?.name;
    this.mobile = data.profile?.mobile;
    this.status = data.profile?.status;
    this.id = data.profile?.id;
  }
  typeOfMessage = (type: string | string[]) => {
    return !!Array.isArray(type);
  }
  closeModal = () => {
    this.dialogRef.close();
  }
  handleChange(e: any, prop: string | number) {
    switch (true) {
      case prop === 'name':
        this.name = e.target.value;
        break;
      case prop === 'email':
        this.email = e.target.value;
        break;
      case prop === 'mobile':
        this.mobile = e.target.value;
        break;
    }
  }
  updateRunner = () => {
    const updateObj = {
      name: this.name,
      mobile: this.mobile,
      status: this.status,
      id: this.id,
    };

    if (!updateObj.mobile){
      this.message = 'Mobile Number cannot be empty';
      this.messageType = 1;
      return;
    }else{
      if (('' + updateObj.mobile).length !== 10){
        this.message = 'Invalid Mobile Number';
        this.messageType = 1;
        return;
      }
    }

    if (!updateObj.name){
      this.message = 'Name cannot be empty';
      this.messageType = 1;
      return;
    }
    this.http
      .put(
        `${environment.SERVER_URL}runners`,
        {
          name: this.name,
          mobile: this.mobile,
          status: this.status,
          id: this.id,
        },
        {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe(
        (data: any) => {
          this.trigger.emit({ success: true });
          this.message = data.message;
          this.messageType = 0;
          setTimeout(() => {
            this.closeModal();
          }, 1000);
        },
        (err) => {
          console.log(err);
          this.message = err.error.message;
          this.messageType = 1;
        }
      );
  }
  showPassword = () => {
    const passwordElement = (document.getElementById('runner-password') as HTMLInputElement);
    console.log(passwordElement);
    if (passwordElement.type === 'password') {
      passwordElement.type = 'text';
    } else {
      passwordElement.type = 'password';
    }
  }
  createRunner = () => {
    this.message = '';
    this.messageType = -1;
    const runnerObj = {
      name: this.name,
      mobile: this.mobile,
      profile: this.profile,
      password: this.password,
    };
    if (!runnerObj.mobile){
      this.message = 'Mobile Number cannot be empty';
      this.messageType = 1;
      return;
    }else{
      if (('' + runnerObj.mobile).length !== 10){
        this.message = 'Invalid Mobile Number';
        this.messageType = 1;
        return;
      }
    }

    if (!runnerObj.name){
      this.message = 'Name cannot be empty';
      this.messageType = 1;
      return;
    }
    this.http
      .post(`${environment.SERVER_URL}runners`, runnerObj, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe(
        (data: any) => {
          this.trigger.emit({ success: true });
          this.message = data.message;
          this.messageType = 0;
          setTimeout(() => {
            this.closeModal();
          }, 1000);
        },
        (err: any) => {
          console.log(err);
          this.message = err.error.message;
          this.messageType = 1;
        }
      );
  }
  ngOnInit(): void {
  }
}
