import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-cloth',
  templateUrl: './edit-cloth.component.html',
  styleUrls: ['./edit-cloth.component.scss'],
})
export class EditClothComponent implements OnInit {
  @Input() drycleaningList: any[] = [];
  @Input() bookingId: number = 0;
  public assets = environment.assetUrl;
  @Output() dryCleanItems = new EventEmitter();
  filteredClothingItems: any[] = [];
  constructor(private http: HttpClient) {}
  ngOnInit(): void {}
  emitData() {
    this.dryCleanItems.emit(this.drycleaningList);
  }
  // handle change quantity based on index
  // handleChange(index: number, event: any) {
  //   console.log({ index, ev: event.target.value });
  // }
  handleAddon(index: number, addon: any) {
    if (!this.drycleaningList[index].addon) {
      this.drycleaningList[index].addon = parseInt(addon);
    } else {
      this.drycleaningList[index].addon = 0;
    }
    this.emitData();
  }
  handleItems(i: any) {
    console.log('WORKING');
    let exists = false;
    let index = -1;
    const temp = [...this.drycleaningList];
    temp.forEach((el, ind) => {
      if (el.cloth_id === i.id) {
        exists = true;
        index = ind;
      }
    });
    if (!exists) {
      temp.push(i);
    } else {
      temp.slice(index, 1);
    }
    console.log({ exist: this.drycleaningList, temp });
    this.drycleaningList = temp;
  }
  search(e: any) {
    // console.log(this.drycleaningList);
    // console.log(this.bookingId);
    // const bookingId = this.drycleaningList[0].booking_id;
    this.http
      .get(
        `${environment.SERVER_URL}cloths?name=${e.value}&booking=${this.bookingId}`
      )
      .subscribe((data: any) => {
        const uniqueItems = data.data.reduce(
          (acc: { [id: number]: any }, item: any) => {
            acc[item.id] = item;
            return acc;
          },
          {}
        );
        this.filteredClothingItems = Object.values(uniqueItems);
      });
    return;
  }
  handleQty(index: number, value: any) {
    console.log(value.target.value);
    if (value.target.value === '') {
      return;
    }
    this.drycleaningList[index].qty = parseInt(value.target.value);
    this.emitData();
  }
  handleRemove(i: number) {
    console.log({ i });
    this.drycleaningList.splice(i, 1);
    console.log({ dry: this.drycleaningList });
    // this.emitData();
  }
}
