import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
// import { EventEmitter } from 'stream';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserComponent implements OnInit {
  public user: any;
  public id: number;
  public email: string;
  public mobile: number;
  public name: string;
  public dob: string;
  public message: any = '';
  public messageType: number = -1;
  public sex: string = '';
  public assets = environment.assetUrl;
  @Output() trigger = new EventEmitter<any>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private utils: UtilityService,
    public dialogRef: MatDialogRef<UpdateUserComponent>
  ) {
    this.user = data.user;
    this.id = data.userId;
    this.email = data.user.email;
    this.mobile = data.user.mobile;
    this.name = data.user.name;
    this.dob = data.user.dob;
  }
  reciveDataFromChild(data: any) {
    console.log(data);
  }
  close() {
    this.dialogRef.close();
  }
  onSubmit() {
    console.log(this.name, this.dob, this.email, this.sex);
    
    const body = {
      email: this.email,
      name: this.name,
      dob: this.dob,
      sex: this.sex,
    };
  
    this.http
      .post(`${environment.SERVER_URL}webuser/${this.id}`, body, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe(
        (data: any) => {
          this.message = data.message;
          this.messageType = 0;
          this.trigger.emit({ success: true });
          setTimeout(() => {
            this.close();
          }, 500);
        },
        (err) => {
          this.message = err.error.message;
          this.messageType = 1;
        }
      );
  }
  onChangeSex(event: any) {
    this.sex = event.target.value;
  }
  typeOfMessage(type: string | string[]) {
    if (Array.isArray(type)) {
      return true;
    } else {
      false;
    }
    return;
  }
  ngOnInit(): void {}
}
