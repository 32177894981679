import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from '../service/utility.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UpdateBreakdownService } from '../update-breakdown.service';
@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.scss'],
})
export class EditServiceComponent implements OnInit {
  public exsitingData: any;
  public addOns: any[] = [];

  public bookingId: number = 0;

  public drycleaningItemList: any[] = [];
  public weight: number;
  public quantity: number;
  public parenttrade = 0;
  @Output() trigger = new EventEmitter<any>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogueRef: MatDialogRef<EditServiceComponent>,
    private utils: UtilityService,
    private http: HttpClient,
    private updateService: UpdateBreakdownService
  ) {
    this.exsitingData = data;
    this.weight = data.weight;
    this.quantity = data.quantity;
    this.bookingId = data.bookingId;
    this.parenttrade = data.parenttrade_id;
  }
  emitTrigger() {
    this.trigger.emit('UPDATE');
  }
  // fetchCloths(query: any) {
  //   this.http
  //     .get(
  //       `${environment.SERVER_URL}cloths?name=${query.target.value}&booking=${this.bookingId}`
  //     )
  //     .subscribe((data: any) => {
  //       this.drycleaningItemList = [];
  //       this.drycleaningItemList = data.data;
  //     });
  // }
  handleCloths(newCloths: any) {
    const existingCloths = this.exsitingData.cloths;

    console.log({ existingCloths, newCloths });

    // Loop through the new cloths array
    newCloths.forEach((newCloth: any) => {
      // Check if the new cloth id already exists in the existing cloths array
      const isClothExist = existingCloths.some(
        (existingCloth: any) => existingCloth.cloth_id === newCloth.id
      );

      // If the cloth does not exist, add it to the existing cloths array
      if (!isClothExist) {
        existingCloths.push(newCloth);
      }
    });

    // Update the this.existingData object with the updated cloths array
    this.exsitingData.cloths = existingCloths;
  }
  handleReciveData(data: any[], type: string) {
    console.log(data);
    if (type === 'dryclean') {
      this.exsitingData.cloths = [...data];
      // this.exsitingData.booking_id = this.bookingId;
    } else if (type === 'premiumPress') {
      this.exsitingData.cloths = [...data];
    }
  }
  handleChange(e: any, type: any) {
    console.log({ e, type });
    if (type == 'qty') {
      this.quantity = parseInt(e);
    } else if (type === 'wei') {
      this.weight = parseInt(e);
    } else if (type === 'addon') {
      let contains = false;
      // 7 is the addon id of the hanger
      this.addOns.forEach((el): void => {
        if (el.id === 7) {
          contains = true;
          return;
        }
      });
      if (contains) {
        this.addOns.find((el) => {
          el.id === 7 ? (el.quantity = e) : null;
        });
      } else {
        this.addOns.push({ id: 7, quantity: e });
      }
    }
  }
  handleAddOn(event: Event) {
    const val = parseInt((event.target as HTMLInputElement).name);
    const addOns = [1, 2];
    if (!addOns.includes(parseInt((event.target as HTMLInputElement).name))) {
      return;
    }
    let indexOfItem = -1;
    let contains: boolean = false;

    this.addOns.forEach((el: any, i: number) => {
      if (el.id === val) {
        contains = true;
        indexOfItem = i;
      }
      return;
    });
    if (!contains) {
      this.addOns.push({
        id: val,
        quantity: 1,
      });
    } else {
      this.addOns.splice(indexOfItem, 1);
    }
  }
  closeModal() {
    this.dialogueRef.close();
  }
  handleSubmitCloth() {
    const body = {
      bookingId: this.bookingId,
      cloths: this.exsitingData.cloths,
      parenttrade_id: this.parenttrade,
    };
    this.http
      .post(`${environment.SERVER_URL}bookingService/update`, body, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        console.log(data);
        this.emitTrigger();
        this.updateService.updateData();
        this.closeModal();
      });
  }
  handleSubmit() {
    console.log(this.exsitingData, this.quantity, this.weight, this.addOns);
    this.exsitingData.quantity = this.quantity;
    this.exsitingData.weight = this.weight;
    const body = { ...this.exsitingData, addons: this.addOns };
    this.http
      .post(`${environment.SERVER_URL}bookingService/update`, body, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe(
        (msg) => {
          console.log('updating.....', msg);
          this.emitTrigger();
          this.updateService.updateData();
          this.closeModal();
        },
        (err) => {
          console.log(err);
        }
      );
  }

  // edit the values
  //make the api call

  ngOnInit(): void {}
}
