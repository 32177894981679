import { Injectable } from '@angular/core';
import html2pdf from 'html2pdf.js';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';
@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  previewInvoice = (id: number) => this.http.get(
      `${environment.SERVER_URL}bookings/generateInvoice/${id}`,
      {
          headers: {
              authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
          responseType: 'blob',
      }
  )
  constructor(private http: HttpClient, private utils: UtilityService) {}
}
