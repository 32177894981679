      <div class="search-bar-section">
        <div class="search-wrapper" (click)="toggleDropdown()">
          <input
            class="search-user"
            [placeholder]="getDateRangeString() || 'Choose Start Date - End Date'"
            readonly
          />
          <img
            [src]="calendar"
            alt="Calendar Icon"
            class="search-icon"
          />
        </div>
        <div class="calendar-body" [class.show]="dropdownVisible">
          <div class="calendar">
            <div class="start-date">
              <h1>Start Date</h1>
              <mat-card class="demo-inline-calendar-card">
            <mat-calendar [(selected)]="startDate" (selectedChange)="onStartDateChange($event)"></mat-calendar>
              </mat-card>
            </div>
            <div class="end-date">
              <h1>End Date</h1>
              <mat-card class="demo-inline-calendar-card">
            <mat-calendar [(selected)]="endDate" (selectedChange)="onEndDateChange($event)"></mat-calendar>
              </mat-card>
            </div>
            <div class="apply">
              <input
                class="input"
                placeholder="Start Date"
            [value]="startDate ? startDate.toLocaleDateString() : ''"
                readonly
              />
              <input
                class="input"
                placeholder="End Date"
            [value]="endDate ? endDate.toLocaleDateString() : ''"
                readonly
              />
              <div class="buttons">
                <button class="btn primary" (click)="applyDateRange()">Apply</button>
                <button class="btn cancel" (click)="cancelSelection()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>