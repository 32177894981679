import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnInit,
  ElementRef,
  Inject,
  Output,
  Input,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Output() trigger = new EventEmitter<any>();
  public message: any = '';
  public messageType: number = -1;
  public messageToDisplay: string = '';
  public postURL: string;
  public bookingId: number;
  public body: any;
  public delete: number = 0;
  public reasons: string[];
  public reason: string = '';
  @Input() logout: boolean = false;
  ngOnInit(): void {}
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private utils: UtilityService,
    private dialogRef: MatDialogRef<ConfirmationModalComponent>,
    private router: Router
  ) {
    this.postURL = data?.postURL;
    this.bookingId = data?.bookingId;
    this.messageToDisplay = data?.messageToDisplay;
    this.body = data.body;
    this.delete = data?.delete;
    // console.log(data)
    this.logout = data?.logout;
    this.reasons = ['Estimated delivery was too long', 'Reason 2 ', 'Reason 3'];
  }
  typeOfMessage(type: string | string[]) {
    if (Array.isArray(type)) {
      return true;
    } else {
      false;
    }
    return;
  }
  closeModal() {
    this.dialogRef.close();
  }
  logoutConfirm() {
    this.utils.deleteLocalStorage('token');
    // this.goToUrl('login');
    this.router.navigateByUrl('login');
    setTimeout(() => {
      this.closeModal();
    }, 500);
  }

  handlePost() {
    if (this.logout) {
      this.logoutConfirm();
      return;
    }
    this.message = '';
    console.log(this.postURL);
    if (this.delete) {
      if (this.reason === '') {
        this.message = 'Please select the reason';
        this.messageType = 1;
        return;
      }
    }
    if (this.delete) {
      this.body.reason = this.reason;
    }
    if (this.body.status === 4 && Array.isArray(this.bookingId)) {
      this.http
        .post(
          `${environment.SERVER_URL}bookings/updateMultiple`,
          {
            bookings: this.bookingId,
            status: this.body.status,
          },
          {
            headers: {
              Authorization: `Bearer ${this.utils.getLocalStorageItem(
                'token'
              )}`,
            },
          }
        )
        .subscribe(
          (data: any) => {
            this.trigger.emit({ success: true });
            console.log(data.data);
            this.message = data.message;
            this.messageType = 0;
            setTimeout(() => {
              this.closeModal();
            }, 2000);
          },
          (err) => {
            console.log({ err });
            console.log({ msg: err.error.message });

            this.message = err.error.message;
            this.message = 1;
          }
        );
      return;
      // this.bookingId.forEach((e) => {
      //   this.http
      //     .post(
      //       `${environment.SERVER_URL}bookings/updateMultipleBookings/${e}`,
      //       this.body,
      //       {
      //         headers: {
      //           Authorization: `Bearer ${this.utils.getLocalStorageItem(
      //             'token'
      //           )}`,
      //         },
      //       }
      //     )
      //     .subscribe(
      //       (data: any) => {
      //         this.trigger.emit({ success: true });
      //         console.log(data.data);
      //         this.message = data.message;
      //         this.messageType = 0;
      //         setTimeout(() => {
      //           this.closeModal();
      //         }, 2000);
      //       },
      //       (err) => {
      //         console.log({ err });
      //         console.log({ msg: err.error.message });
      //         this.message = err.error.message;
      //         this.message = 1;
      //       }
      //     );
      // });
    }
    this.http
      .post(`${environment.SERVER_URL}${this.postURL}`, this.body, {
        headers: {
          Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe(
        (data: any) => {
          this.trigger.emit({ success: true });
          console.log(data.data);
          this.message = data.message;
          this.messageType = 0;
          setTimeout(() => {
            this.closeModal();
          }, 2000);
        },
        (err) => {
          console.log({ err });
          console.log({ msg: err.error.message });

          this.message = err.error.message;
          this.message = 1;
        }
      );
  }
}
