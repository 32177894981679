import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-month-wise-category-report',
  templateUrl: './month-wise-category-report.component.html',
  styleUrls: ['./month-wise-category-report.component.scss'],
})
export class MonthWiseCategoryReportComponent implements OnInit {
  public year: any;
  public currentYear: number = new Date().getFullYear();
  public data: any[] | null = [];


  constructor(private http: HttpClient, private utility: UtilityService) {
    this.year = this.currentYear;
  }
  ngOnInit(): void {
    // console.log();
    this.generateReport();
  }
  getMonth(month: string) {
    const months = [
      '',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[parseInt(month)];
  }
  validateYear(event: Event) {
    const input = event.target as HTMLInputElement;
    const value = parseInt(input.value, 10);
    if (value > this.currentYear) {
      input.value = this.currentYear.toString();
      this.year = this.currentYear;
    } else {
      this.year = value;
    }
  }
  generateReport() {
    this.data = null;
    this.http
      .get(`${environment.SERVER_URL}stores/generateMWCR?year=${this.year}`, {
        headers: {
          authorization: `Bearer ${this.utility.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        console.log(data);
        this.data = data.data;
      });
  }
}
