import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cancelled-orders-reports',
  templateUrl: './cancelled-orders-reports.component.html',
  styleUrls: ['./cancelled-orders-reports.component.scss'],
})
export class CancelledOrdersReportsComponent implements OnInit {
  public from: string = '';
  public message: any = '';
  public messageType: number = -1;
  public to: string = '';
  public today: string = '';
  public data: any[] | null = [];
  constructor(private http: HttpClient, private utility: UtilityService) {}
  ngOnInit() {
    this.setToday();
  }

  setToday() {
    const today = new Date();
    this.today = today.toISOString().split('T')[0];
  }

  typeOfMessage(type: string | string[]) {
    if (Array.isArray(type)) {
      return true;
    } else {
      false;
    }
    return;
  }

  
  validateDates() {
    if (this.from && this.to && this.from > this.to) {
      this.to = this.from;
    }
  }

  generateReport() {
    switch (true) {
      case this.from === '':
        this.message = 'From date is required';
        this.messageType = 1;
        return;

      case this.to === '':
        this.message = 'To date is required';
        this.messageType = 1;
        return;
    }
    this.data = null;
    this.http
      .post(
        `${environment.SERVER_URL}stores/generateCOR`,
        {
          from_date: this.from,
          to_date: this.to,
        },
        {
          headers: {
            authorization: `Bearer ${this.utility.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe(
        (data: any) => {
          console.log(data);
          this.data = data.data;
        },
        (err) => {
          this.message = err.error.message;
        }
      );
  }
}
