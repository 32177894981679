export const environment = {
    production: false,
    assetUrl: 'https://storeassets.ucleanlaundry.com/',
    SERVER_URL: getServerUrl(),
    domain: 'https://store.whitetigercleaners.com'

};

function getServerUrl(): string {
    const currentDomain = window.location.origin;
    if (currentDomain.includes('whitetigercleaners.com')) {
        return `${currentDomain}/api/v1/`;
    } else if (currentDomain.includes('ucleanlaundry.com')) {
        return `${currentDomain}/api/v1/`;
    } else {
        // Default to a generic URL if domain doesn't match
        return 'http://localhost:1338/api/v1/';
    }
}
