import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {CouponsService} from 'src/app/service/coupons.service';
import {UtilityService} from 'src/app/service/utility.service';
import {ApiService} from 'src/app/services/api.service';
import {environment} from 'src/environments/environment';


@Component({
  selector: 'app-add-coupon-step3',
  templateUrl: './add-coupon-step3.component.html',
  styleUrls: ['./add-coupon-step3.component.scss'],
})
export class AddCouponStep3Component implements OnInit {
  public allServices: any[] = [];
  public selectedItems: string[] = [];
  public selectedItemIds: number[] = [];

  dropdownSettings: IDropdownSettings = {};
  public message = '';
  public messageType = -1;

  constructor(
    private couponService: CouponsService,
    private http: HttpClient,
    private utils: UtilityService,
    private router: Router,
    public apiService: ApiService,
  ) {
    this.dropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
      noDataAvailablePlaceholderText: 'There is no item available to show',
      allowSearchFilter: true,
    };
  }

  setError(title: string): boolean {
    this.message = title;
    this.messageType = 1;
    return false;
  }

  onSelectAll = (event: any) => {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.allServices.length; i++) {
      const service = this.allServices[i];
      if (this.selectedItems.indexOf(service.item_text) < 0) {
        this.selectedItems.push(service.item_text);
        this.selectedItemIds.push(service.item_id);
      }
    }
  }
  onDeselectAll = () => {
    this.selectedItems = [];
    this.selectedItemIds = [];
  }
  onItemSelect = (item: any) => {
    this.selectedItems.push(item.item_text);
    this.selectedItemIds.push(item.item_id);
  }
  onItemDeSelect = (item: any) => {
    const index = this.selectedItems.indexOf(item.item_text);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
    }
    const index2 = this.selectedItemIds.indexOf(item.item_id);
    if (index > -1) {
      this.selectedItemIds.splice(index2, 1);
    }
  }
  validateData = () => {
    if (this.selectedItems.length <= 0) {
      this.setError('please select services')
      return false;
    }
    return true;
  }
  getServices = () => {
    this.http
      .get(`${environment.SERVER_URL}parenttrades`, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        const formattedData = data.services.map((el: any) => ({
          ...el,
          item_id: el.id,
          item_text: el.name
        }));
        this.allServices = formattedData;
      });
  }
  goToUrl = (url: string) => {
    this.router.navigateByUrl(url);
  }
  goToNextStep = () => {
    if (this.validateData()){
      const step3Data = {
        selectedItems: this.selectedItems,
        selectedItemIds: this.selectedItemIds,
        countOfServices: this.allServices.length
      };
      this.couponService.setData(step3Data, 3);
      this.router.navigateByUrl('add-coupons-step-4');
    }
  }

  ngOnInit(): void {
    this.getServices();
    this.couponService.getData(3).subscribe((data) => {
      if (!data) {
        return;
      } else {
        this.selectedItems = data.selectedItems;
        this.selectedItemIds = data.selectedItemIds;
      }
    });
  }
}
