<section class="order-flow-section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="search-bar-section">
                    <div *ngIf="!typeOfMessage(message)">
                        <app-notifications
                                [message]="message"
                                [type]="messageType"
                        ></app-notifications>
                    </div>
                    <div *ngIf="typeOfMessage(message)">
                        <div *ngFor="let e of message">
                            <app-notifications [message]="e" [type]="1"></app-notifications>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="white-container mt-20">
                    <ng-container *ngTemplateOutlet="box"></ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="white-container mt-20">
                    <div class="section-header w60">
                        <p>Order History</p>
                    </div>
                    <div *ngFor="let a of customerPreviousOrders; let i = index">
                        <div class="uclean-dflex-spacebetween w60 mt-30">
                            <div class="uclean-dflex order-no">
                                <img
                                        src="{{ assets }}assets/images/icons/order-icon.svg"
                                        alt="icon"
                                />
                                <p>{{ a.booking_code }}</p>
                            </div>
                            <div>
                                <div class="uclean-dflex uclean-column-reverse status">
                                    <img
                                            src="{{ assets }}assets/images/icons/deliver-icon.svg"
                                            alt="icon"
                                    />
                                    <p>{{ renderStatus(a.status) }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="uclean-dflex-spacebetween w60 mt-10">
                            <div>
                                <p>Dry cleaning/Steam press</p>
                            </div>
                            <div>
                                <p>{{ a.address }}</p>
                            </div>
                        </div>
                        <div class="uclean-dflex-spacebetween w60 mt-10">
                            <div>
                                <p>
                                    Order date & time -
                                    {{ getFormattedDate(a?.created_at) }}
                                </p>
                            </div>
                            <div>
                                <!-- <p>Kameez (3), Pajama (1) & Plazo (2)</p> -->
                            </div>
                        </div>
                        <div class="uclean-dflex-spacebetween w60 mt-10">
                            <div>
                                <p>
                                    Delivery date & time - {{ getFormattedDate(a.delivery_date) }}
                                </p>
                            </div>
                            <div>
                                <p>₹{{ a?.final_amount }}</p>
                            </div>
                        </div>
                        <hr class="w60"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #box>
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="box box1">
                    <div class="section-header">
                        <p>Customer Details</p>
                        <span (click)="openUpdateUser()">Edit Info</span>
                    </div>
                    <app-customer-address-detail-form
                            (addAddressTrigger)="openAddressModal()"
                            [userDetails]="currentUser"
                            [userAddresses]="customerAddress"
                            (addressId)="reciveDataFromChild($event, 'ADDID')"
                    ></app-customer-address-detail-form>
                </div>
            </div>
            <div class="col-md-4" style="height: 500px">
                <div class="box box2">
                    <div class="section-header">
                        <p>Create Order</p>
                    </div>
                    <app-order-detail-form
                            (order)="reciveDataFromChild($event, 'ORDER')"
                    ></app-order-detail-form>
                    <div class="btn-wrapper">
                        <button class="btn-primary" (click)="handleCreateOrder()">
                            Add Order
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box box3">
                    <div class="section-header">
                        <p>customer activity</p>
                    </div>
                    <app-customer-activity-details
                            [userHistory]="customerHistory"
                    ></app-customer-activity-details>
                </div>
            </div>
        </div>
    </div>
</ng-template>
