import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gst-date-component',
  templateUrl: './gst-date-component.component.html',
  styleUrls: ['./gst-date-component.component.scss']
})
export class GstDateComponentComponent implements OnInit {

  @Output() dateRangeSelected = new EventEmitter<{ from: string, to: string }>();

  dropdownVisible = false;
  startDate: Date | null = null;
  endDate: Date | null = null;
  showCalendar = false;
  selected: any;
  
  calendar = [
    `${environment.assetUrl}assets/images/calendar-event-create.svg`
   ];

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  onStartDateChange(event: Date) {
    this.startDate = event;
  }

  onEndDateChange(event: Date) {
    this.endDate = event;
  }

  applyDates() {
    // Logic to apply selected dates
    this.dropdownVisible = false;
  }

  cancelSelection() {
    // Logic to cancel date selection
    this.dropdownVisible = false;
  }

  exportReport() {
    // Implement export functionality
  }
  constructor() { }

  ngOnInit(): void {
  }

  toggleCalendar(): void {
    this.showCalendar = !this.showCalendar;
  }

  onDateChange(event: MatDatepickerInputEvent<Date>, type: 'start' | 'end'): void {
    if (type === 'start') {
      this.startDate = event.value;
    } else {
      this.endDate = event.value;
    }
  }


  getDateRangeString(): string {
    if (this.startDate && this.endDate) {
      return `${this.formatDate(this.startDate)} - ${this.formatDate(this.endDate)}`;
    }
    return '';
  }

  formatDate(date: Date): string {
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  }
  applyDateRange(): void {
    this.showCalendar = false;
    if (this.startDate && this.endDate) {
      this.dateRangeSelected.emit({
        from: this.formatDateForApi(this.startDate),
        to: this.formatDateForApi(this.endDate)
      });
    }
  }

   formatDateForApi(date: Date): string {
    return date.toISOString().split('T')[0]; // Format: YYYY-MM-DD
  }

  // ... (other methods remain the same)
}