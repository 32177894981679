import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @Input() message: string = '';
  @Input() type: number = 1; // 0 for success 1 for error
  public typeofMessage = 0; // 0 for string 1 for array

  constructor() {
    this.message = '';
  }
  dismissNotification = () => {
    this.message = '';
    this.type = 0;
  }
  ngOnInit(): void {}

  ngOnChanges = (): void => {
    if (Array.isArray(this.message)) {
      this.typeofMessage = 1;
    } else {
      this.typeofMessage = 0;
    }

    if (this.message !== '') {
      setTimeout(() => {
        this.dismissNotification();
      }, 5000);
    }
  }
}
