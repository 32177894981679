<section class="customer-directory-section">
  <div class="row">
    <div class="col-md-12 couponsSearch">
      <div class="searching-container">
        <div class="first-half-section">
          <div class="search-bar-section">
            <!-- <app-search-bar [placeholderName]="'Search coupons'">
            </app-search-bar> -->
            <input
              class="search-user"
              (keyup)="searchCoupons($event)"
              placeholder="Search coupons with coupon code"
            />
          </div>
        </div>

        <div class="second-half-section" style="margin-left: 100px">
          <button
            mat-ripple
            class="add-order-btn"
            (click)="gotoUrl('add-coupons-step-1')"
          >
            Add Coupon <span class="vertical-line"></span>&nbsp;+
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="table-section mt-20 center">
          <div *ngIf="data.length === 0" class="loader-container">
            <app-loader></app-loader>
          </div>
          <table *ngIf="data.length !== 0">
            <tr class="header-tr">
              <td class="header width-7">Sr No.</td>
              <td class="header width-10">Discount</td>
              <td class="header width-15">Min Order</td>
              <td class="header">Services</td>
              <td class="header">Order</td>
              <td class="header">Code</td>
              <td class="header width-10">Start Date</td>
              <td class="header width-10">End Date</td>
              <td class="header">Status</td>
            </tr>
            <!-- <div class="table-body-wrapper"> -->
            <tr *ngFor="let e of data; let i = index">
              <td class="width-7">
                <span
                  ><b class="">{{ i + 1 }}</b></span
                >
              </td>
              <td class="width-10">
                <span>{{ e.discount }}</span
                ><br />
              </td>
              <td class="width-15">
                <span class="txt-align-center"
                  >Minumum Order : {{ e.minimum_order }}</span
                >
                <span class="txt-align-center"
                  >Maximum Discount : {{ e.maximum_discount }}</span
                >
              </td>
              <td>
                <span class="txt-align-center">{{ e.name }}</span>
              </td>
              <td>
                <span class="green-text">{{
                  e.order_no === 2 ? "Any Order" : "First Order"
                }}</span>
              </td>
              <td>
                <span class="txt-align-center code" matRipple>
                  <b>{{ e.discount_code }}</b>
                </span>
              </td>
              <td class="width-10">
                <span class="txt-align-center">{{ e.start_date | date }}</span>
              </td>
              <td class="width-10">
                <span class="txt-align-center">{{ e.end_date | date }}</span>
              </td>
              <td>
                <span
                  [class]="{
                    cancelled: renderStatus(e) === 'Cancelled',
                    expire: renderStatus(e) === 'Expired',
                    valid: renderStatus(e) === 'Valid'
                  }"
                  class="txt-align-center"
                  >{{ renderStatus(e) }}</span
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
