<div class="panel">
  <div class="panel-header">
    <br />
    <h2 class="">Clothing Items</h2>
    <br />
    <input type="text" placeholder="Search" (input)="search($event.target)" />
  </div>
  <div class="clothing-panel">
    <div class="pannelItem" *ngFor="let item of filteredClothingItems">
      <h3>{{ item.name }} // {{ item.subtrade_name }}</h3>
      <button (click)="handleItems(item)">Add</button>
    </div>
  </div>
</div>
<section class="dry-cleaning-list-section">
  <div class="list-section">
    <div class="list-header">
      <div class="list-header-item">
        <div class="list-header-item-td"><p>Hanger</p></div>
        <div class="list-header-item-td"><p>Item</p></div>
        <div class="list-header-item-td"><p>Quantity</p></div>
        <div class="list-header-item-td"><p>Price</p></div>
        <div class="list-header-item-td"><p>Comment</p></div>
        <div class="list-header-item-td"><p>Cancel</p></div>
      </div>
    </div>
    <div class="list-body">
      <div
        class="list-body-item"
        *ngFor="let obj of drycleaningList; let i = index"
      >
        <div
          class="list-body-item-td checkbox-column"
          (change)="handleAddon(i, 7)"
        >
          <div class="checkbox-option">
            <input
              class="checkbox-input"
              type="checkbox"
              id="antisepticWash"
              name="antisepticWash"
              value="7"
            />
          </div>
        </div>
        <div class="list-body-item-td">
          <div class="input-container gray-text">
            <input
              class="priceQty"
              type="text"
              [disabled]="true"
              value="{{ obj.name }}"
            />
          </div>
        </div>
        <div class="list-body-item-td">
          <!-- <app-counter (count)="handleQty(i, $event)"></app-counter> -->
          <input
            class="priceQty"
            value="{{ obj.qty }}"
            (input)="handleQty(i, $event)"
          />
        </div>
        <div class="list-body-item-td">
          <div class="input-container">
            <input
              [disabled]="true"
              type="text"
              value="{{ obj.price || obj.standard_pric || obj.premium_price }}"
            />
          </div>
        </div>
        <div class="list-body-item-td">
          <img src="{{ assets }}assets/images/icons/comment.svg" />
        </div>
        <div class="list-body-item-td" (click)="handleRemove(i)">
          <img src="{{ assets }}assets/images/icons/cross.svg" />
        </div>
      </div>
    </div>
  </div>
</section>
