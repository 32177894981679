import { HttpClient } from '@angular/common/http';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/service/utility.service';
import { AppService } from 'src/app/service/app.service';

@Component({
  selector: 'app-customer-directory',
  templateUrl: './customer-directory.component.html',
  styleUrls: ['./customer-directory.component.scss'],
})
export class CustomerDirectoryComponent implements OnInit {
  // public drycleaningItemList = DRY_CLEANING_LIST;
  public users: any = null;
  public searchedUsers: any;
  public dropIcon = `${environment.assetUrl}assets/images/icons/dropdown.svg`;
  public pickupIcon = `${environment.assetUrl}assets/images/icons/pickup.svg`;
  public totalUsers: number = 0;
  public currentPage: number = 1;
  public totalPages: number = 0;
  public sortingItems: any = [];
  public sort: string = '';
  public search: string = '';
  constructor(
    private http: HttpClient,
    public apiRep:AppService,
    private router: Router,
    private utils: UtilityService
  ) {
    this.sortingItems = [
      {
        name: 'Ascending total value',
        value: 'TVACS',
      },
      {
        name: 'Descending total value',
        value: 'TVDES',
      },
      {
        name: 'Ascending total bookings',
        value: 'TBACS',
      },
      {
        name: 'Descending total bookings',
        value: 'TBDES',
      },
      {
        name: 'Ascending last order',
        value: 'LOACS',
      },
      {
        name: 'Descending last order',
        value: 'LODES',
      },
    ];
  }

  // sortData () {}
  updatePageNumber(ev: any) {
    this.currentPage = ev.page;
    if (this.search.length !== 0) {
      return this.getusersMobile();
    }
    this.getMethod();
  }
  ngOnInit(): void {
    document.documentElement.style.setProperty(
      '--search-bar-default-width',
      '40vw'
    );
    if(this.apiRep.currentSort){
      this.sort=this.apiRep.currentSort;
      // this.apiRep.currentSort='';
    }
    this.getMethod();
  }
  sortData(val: any) {
    this.users = null;
    this.sort = val;
    this.apiRep.currentSort=val;
    this.currentPage = 1;
    if (this.search.length !== 0) {
      return this.getusersMobile();
    }
    this.getMethod();
  }
  getFormattedDate(date: string) {
    // var date = new Date();
    if (!date) {
      return '';
    }
    const newDate = new Date(date).toDateString();
    // var transformDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    return newDate;
  }

  formatNumber(numb: number) {
    return Math.round(numb);
  }
  handleSearch(input: any) {
    console.log("handle Check")
    this.currentPage = 1;
    if (input.target.value.length === 0) {
      console.log('no input');
      this.search = '';
      return this.getMethod();
    }
    this.search = input.target.value;
    this.getusersMobile();
  }
  public getusersMobile() {
    console.log(this.search);
    this.http
      .get(
        `${environment.SERVER_URL}webuser/searchUserMobile?mobile=${this.search}&sort=${this.sort}&page=${this.currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe(
        (data: any) => {
          this.users = data.data;
          this.totalPages = data.totalPages;
        },
        (error: HttpErrorResponse) => {
          console.error('Error fetching users:', error.message);
        }
      );
  }
  
  public getMethod() {
    console.log(this);
    this.http
      .get(
        `${environment.SERVER_URL}webuser/getusers?page=${this.currentPage}&sort=${this.sort}`,
        {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe((data: any) => {
        console.log(data);
        this.users = data.data;
        this.totalPages = data.totalPages;
      });
  }
  public goToUrl(url: string, user: string) {
    this.router.navigateByUrl(`${url}${user}`);
  }
}
