import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-add-address-form',
  templateUrl: './add-address-form.component.html',
  styleUrls: ['./add-address-form.component.scss'],
})
export class AddAddressFormComponent implements OnInit {

  constructor() {}
  public activeAddressTypeIndex = 0;
  public flatInfo = '';
  public apartmentRoad = '';
  public pincode = '';
  public landmark = '';
  public contactPerson = '';
  public contactNumber = '';
  public pocketInfo = '';
  public assetUrl = environment.assetUrl;
  @Output() address = new EventEmitter<any>();
  emmitData = () => {
    this.address.emit({
      flat_info: this.flatInfo,
      street_info: this.apartmentRoad,
      pincode: this.pincode,
      contact_person: this.contactPerson,
      contact_number: this.contactNumber,
      landmark: this.landmark,
      pocket_info: this.pocketInfo,
      type: this.addressTypeList[this.activeAddressTypeIndex].name,
      city_id: 3,
    });
  }
  assignValues = (val: any, prop: string) => {
    // console.log(val);
    switch (true) {
      case prop === 'flat':
        this.flatInfo = val.target?.value;
        break;
      case prop === 'apart':
        this.apartmentRoad = val.target?.value;
        break;
      case prop === 'pin':
        this.pincode = val.target?.value;
        break;
      case prop === 'land':
        this.landmark = val.target?.value;
        break;
      case prop === 'cNum':
        this.contactNumber = val.target.value;
        break;
      case prop === 'cName':
        this.contactPerson = val.target.value;
        break;
      case prop === 'pocket':
        this.pocketInfo = val.target.value;
        break;
    }
  }
  resetForm() {
    document.querySelectorAll('.inuputField').forEach((el) => {
      el.innerHTML = '';
    });
  }
  public addressTypeList = [
    {
      name: 'Home',
      icon: `${environment.assetUrl}assets/images/icons/home.svg`,
    },
    {
      name: 'Work',
      icon: `${environment.assetUrl}assets/images/icons/order-through/laptop.svg`,
    },
    {
      name: 'Family',
      icon: `${environment.assetUrl}assets/images/icons/family.svg`,
    },
    { name: 'Others', icon: `${environment.assetUrl}assets/images/icons/others.svg` },
    { name: 'Hotel', icon: `${environment.assetUrl}assets/images/icons/hotelIcon.svg` },
  ];
  ngOnInit(): void {}
}
