import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/service/utility.service';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-daily-sales-report',
  templateUrl: './daily-sales-report.component.html',
  styleUrls: ['./daily-sales-report.component.scss'],
})
export class DailySalesReportComponent implements OnInit {
  today: string = new Date().toISOString().split('T')[0];
  from: string = '';
  to: string = '';
  message: string = '';
  selectedReport: string = 'DSR'; 
  messageType: number = 0;
  data: any[] | null = null;

  constructor(private http: HttpClient, private apiService:  ApiService) {}

  ngOnInit() {
    // Initialize component
  }
  onDateRangeSelected(dateRange: { from: string, to: string }) {
    this.from = dateRange.from;
    this.to = dateRange.to;
    this.selectedReport = 'DSR';
    this.generateReport();
  }

  validateDates() {
    // Implement date validation logic
  }

  generateReport() {

    this.data = null;
    this.apiService.generateDSR(this.from, this.to).subscribe(
      (data: any) => {
        console.log(data);
        this.data = data.data;
      },
      (err) => {
        this.message = err.error.message;
        this.messageType = 1;
      }
    );
  }

  showReport(reportType: string) {
    this.selectedReport = reportType;
    this.generateReport(); // Fetch data when switching reports
  }
}
