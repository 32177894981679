// app.component.ts
import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DRAWER_TYPES } from 'src/assets/constants/app-constant';
import { AppService } from './service/app.service';
import { DOCUMENT } from '@angular/common';
import { UtilityService } from './service/utility.service';
import { DynamicServerUrlService } from './service/dynamic-server-url.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'uclean-suite';
  drawerSubscription!: Subscription;
  isOrderBreakupDrawerVisibile = false;
  blackThemeDomain = 'store.whitetigercleaners.com';
  appDomain = '';

  constructor(
    private appService: AppService,
    private dynamicServerUrlService: DynamicServerUrlService,
    @Inject(DOCUMENT) private document: Document,
    private utilityService: UtilityService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const domain = params['domain'];
      this.appDomain = this.utilityService.extractDomainFromUrl(environment.domain);
      console.log('Domain:', domain); // Log the domain parameter

      let serverUrl = '';
      if (domain === 'ucleanlaundry.com') {
        serverUrl = 'https://store.ucleanlaundry.com/api/v1/';
      } else if (domain === 'whitetigercleaners.com') {
        serverUrl = 'https://store.whitetigercleaners.com/api/v1/';
      } else {
        // Default server URL
        serverUrl = 'https://store.whitetigercleaners.com/api/v1/';
      }

      console.log('SERVER_URL:', serverUrl); // Log the SERVER_URL
      this.dynamicServerUrlService.setServerUrl(serverUrl);
  
      console.log("asdfghjk",this.appDomain);
    if (this.appDomain === this.blackThemeDomain) {

      // Apply black theme styles
      this.document.documentElement.style.setProperty("--primary-color", "#65ba0d");
      this.document.documentElement.style.setProperty("--secondary-color", "#65ba0d");
      this.document.documentElement.style.setProperty("--primary-gradient-color", "linear-gradient(180deg, #0b583a 0%, #053b26 92.5%)");
      this.document.documentElement.style.setProperty("--black-bg", "#65ba0d");
      this.document.documentElement.style.setProperty("--yellow-bg", "#003a26");
      this.document.documentElement.style.setProperty("--primary-gradient-color", "#65ba0d");
    } else {
      // Apply default theme styles
      this.document.documentElement.style.setProperty("--primary-color", "#65ba0d");
      this.document.documentElement.style.setProperty("--secondary-color", "#65BA0D");
      this.document.documentElement.style.setProperty("--primary-gradient-color", "linear-gradient(180deg, #0b583a 0%, #053b26 92.5%)");
      this.document.documentElement.style.setProperty("--black-bg", "#323232");
      this.document.documentElement.style.setProperty("--yellow-bg", "#E2E225");
    }
  });

    this.setDrawerSubscription();
  }

  setDrawerSubscription() {
    this.drawerSubscription = this.appService.drawer$.subscribe(
      (drawerInfo: any) => {
        if (drawerInfo) {
          if (drawerInfo?.type == DRAWER_TYPES.orderBreakup) {
            this.isOrderBreakupDrawerVisibile = drawerInfo?.isVisible;
          }
        }
      }
    );
  }

  closeOrderBreakupDrawer() {
    this.appService.drawer$.next({
      type: DRAWER_TYPES.orderBreakup,
      isVisible: false,
    });
  }

  ngOnDestroy() {
    this.drawerSubscription.unsubscribe();
  }
}
