import {
    Component,
    OnInit,
    ElementRef,
    Inject,
    ViewChild,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import * as html2pdf from 'html2pdf.js';



@Component({
    selector: 'app-view-invoice',
    templateUrl: './view-invoice.component.html',
    styleUrls: ['./view-invoice.component.scss'],
})
export class ViewInvoiceComponent implements OnInit {
    public htmlContent: string;
    public css: string;
    @ViewChild('invoice') invoiceElement: ElementRef;

    // htmlContent1: string = '<!-- Your HTML invoice content here -->';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ViewInvoiceComponent>
    ) {
        const element = document.createElement('div');
        element.innerHTML = data;
        const bodyElement = element.querySelector('div.invoice-container');
        this.htmlContent = bodyElement ? bodyElement.innerHTML : '';
        const styleElement = element.querySelector('style');
        this.css = styleElement ? styleElement.innerText : '';
        const styleSheet = document.createElement('style');
        styleSheet.innerText = this.css;
        document.head.appendChild(styleSheet);
    }

    ngOnInit = (): void => {
        const styleSheet = document.createElement('style');
        styleSheet.innerText = this.css;
        document.head.appendChild(styleSheet);
    }

    onClose = () => {
        this.dialogRef.close();
    }
    processImagesInElement = async (element) => {
        // Helper function to convert an image Blob to a base64 string
        const toBase64 = (blob) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        };

        // Extract all image elements within the provided element
        const images = element.querySelectorAll('img');
        for (let img of images) {
            const imgURL = img.src;

            try {
                // Fetch the image
                const response = await fetch(imgURL);
                if (!response.ok) throw new Error(`Failed to fetch image: ${imgURL}`);

                // Convert the image response to a Blob
                const imageBlob = await response.blob();

                // Convert the Blob to a base64 string
                const base64Data = await toBase64(imageBlob);

                // Replace the image source with the base64 data
                img.src = base64Data;
            } catch (error) {
                console.error('Error processing image:', error);
            }
        }
    }
    public async openPDF(): Promise<void> {
      const DATA = document.getElementById('invoice');
      if (!DATA) {
          console.error('Invoice element not found');
          return;
      }
  
      // Dynamically create and add CSS
      const style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = `
          .body-footer {
              page-break-after: always;
          }
      `;
      document.head.appendChild(style);
  
      try {
          await this.processImagesInElement(DATA);
          console.log('Images have been processed and updated with base64 data.');
  
          const options = {
          
              filename: 'Download-invoice.pdf',
              image: { type: 'jpeg', quality: 0.95 },
              html2canvas: { 
                  scale: 1.5,
                  useCORS: true
              },
              jsPDF: { 
                  unit: 'mm', 
                  format: 'a4', 
                  orientation: 'portrait',
                  compress: true
              },
          };
  
          await html2pdf().set(options).from(DATA).save();
          console.log('PDF generated and saved successfully');
      } catch (error) {
          console.error('Error generating PDF:', error);
      } finally {
          // Remove the dynamically added style
          document.head.removeChild(style);
      }
  }
  
  printWithHeaderAndFooter(): void {
    const divContents = this.htmlContent;
    const cssContent = this.css + `
      @page {
        margin: 10;
      }
      @media print {
        body { 
          margin: 8.5mm !important;
          font-family: "Poppins" !important;
        }
        footer {
          position: fixed;
          bottom: 0;
        }
        header {
          position: fixed;
          top: 0;
        }
        li {
          margin-bottom: 10px !important;
          font-size: 12.5px !important;
        }
        .web {
          padding-left: 400px !important;
        }
        .terms-header {
          display: none !important;
        }
        .text {
          font-weight: 500 !important;
          font-size: 20px !important;
        }
        .icon {
          width: 20px !important;
          height: 20px !important;
          padding-left: 5px !important;
          padding-right: 5px !important;
        }
        
                .invoice-body-inner {
                margin-top: 70px !important;
        overflow: visible !important;
      }
      .page-break {
        page-break-before: always !important;
      }
        .invoice-body {
          font-family: Poppins, sans-serif !important;
        } 
             .table-padding {
             margin-top: 70px !important;
      }
        .body-footer {
          padding-top: 27.97px !important;
          padding-left: 90px !important;
        }
        .logo-image{
           width: 225px !important;
height: 64.89px !important;
        }
        .footer {
          position: absolute !important;
          bottom: 0 !important;
        }
        .page-text {
          display: none !important;
        }
        .terms-footer {
          display: none !important;
        }
        .terms-page {
          page-break-before: always;
        }
        .tnc-body {
          margin-top: 100px !important;
        }

        .custom-block{
          margim-top: 60px !important;
        }
      
      }
    `;
    const content = window.open('', '');
    if (content) {
      content.document.write(`
        <html>
          <head>
            <style>${cssContent}</style>
          </head>
          <body>${divContents}</body>
        </html>`
      );
      content.document.close();
      
            // Apply page break logic for the table and custom-block
    const table = content.document.querySelector('table') as HTMLTableElement;
    const customBlock = content.document.querySelector('.custom-block') as HTMLElement;
    
    if (table && customBlock) {
      const maxHeightPx = 500; // Set to 30px as per your requirement
      
      if (table.offsetHeight > maxHeightPx) {
        const pageBreak = content.document.createElement('div');
        pageBreak.className = 'page-break';
        
        const tablePadding = content.document.createElement('div');
        tablePadding.className = 'table-padding';
        
        // Insert table padding before the custom-block
        customBlock.parentNode.insertBefore(tablePadding, customBlock);
        
        // Insert page break after the table
        table.parentNode.insertBefore(pageBreak, table.nextSibling);
      }
    }
  
      // Force logo to black version
      const logoImg = content.document.getElementById('dynamicImage') as HTMLImageElement;
      if (logoImg) {
        logoImg.src = 'https://storeassets.ucleanlaundry.com/assets/images/uc-black.png';
      }

      // Wait for the black logo to load
      const waitForBlackLogo = new Promise<void>((resolve) => {
        if (logoImg) {
          if (logoImg.complete) {
            resolve();
          } else {
            logoImg.onload = () => resolve();
            logoImg.onerror = () => {
              console.error('Failed to load black logo');
              resolve(); 
            };
          }
        } else {
          resolve();
        }
      });
      
      waitForBlackLogo.then(() => {
        setTimeout(() => {
          content.print();
        }, 100);
      });
      
      setTimeout(() => {
        if (!content.closed) {
          console.warn('Printing timeout reached, forcing print');
          content.print();
        }
      }, 5000); // 5 second fallback
    } else {
      console.error('Failed to open new window for printing');
    }
  }
  
  

    public printWithoutHeaderAndFooter(): void {
        const divContents = this.htmlContent;
        const cssContent = this.css + `
        @page {
          margin: 80;
          @top-center {
            content: none;
          }
          @bottom-center {
            content: none;
          }
        }
      `;
        const content = window.open('', '');
        content.document.write(`
      <html>
        <head>
          <style>
            ${cssContent}
            @media print {
            body{
            margin-top: 50px;
                        }
               .terms-page{
            display:none !important
            }
              body {
                margin: 0;
                padding: 0;
              }
              header {
                display: none !important;
              }
              .footer-bottom{
                display: none !important;
              }
              .body-footer{
                display: none !important;
              }
              #invoice, #invoice * {
                visibility: visible;
              }
              #invoice {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
              }
            }
          </style>
        </head>
        <body>${divContents}</body>
      </html>
    `);
        content.print();
        content.document.close();
    }
}
